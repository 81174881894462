
/*header bar*/

.wrap_header_bar_hd {
    /*width: 100%;*/
    background: #5e5ab9;
    padding: 12px 24px;
    box-sizing: border-box;

    /*position: fixed;*/
    width: 100%;
    z-index: 1002;

    top: 0;

    /*border-right: 2px solid #2f2a61;*/
}

.wrap_tools_header_hd {
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    /*flex-direction: column;*/

    position: relative;

    width: 100%;
}

/*header logo and title*/

.wrap_logo_and_title_hd {
    display: flex;
    align-items: center;

    /*border-bottom:2px solid #2f2a61;*/

    /*padding: 12px;*/
}

.wrap_header_logo_hd {
    width: 60px;
    height: 60px;
    cursor: pointer;
}

.header_logo_hd {
    width: 100%;
    height: 100%;
}

.wrap_header_title_hd {
    margin-left: 12px;
}

.header_title_hd {
    font-size: 24px;
    color: #EEEEEE;
    font-weight: bold;
}

/*header content blocks*/

.wrap_menu_struct_blocks_hd{
    display: flex;
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    /*flex: 1;*/
}

.wrap_menu_blocks_hd {
    display: flex;
}

.menu_hide_btn {
    padding: 12px;
    font-size: 16px;
    color: #EEEEEE;
    font-family: "gilroy", serif;
}

/*menu block*/

.wrap_menu_block_hd {
    /*padding: 12px;*/
    display: flex;
    align-items: center;
    margin-right: 24px ;

    position: relative;
}

.wrap_menu_block_hd.is_child_true {
    margin-right: 0px ;
    /*width: 100%;*/

    display: flex;
    justify-content: center;
    padding: 12px;
}

.wrap_menu_block_hd.is_child_true:hover {
    background: #a6b1bf30;
    border-radius: 2px;
}

.menu_block_content_hd {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.4s;
    opacity: 1;
}

.menu_block_content_hd:hover {
    /*opacity: 0.4;*/
}

.wrap_icon_menu_block_hd {
    display: flex;
    align-items: center;
    justify-content: center;
    /*width: 60px;*/
}

.wrap_title_menu_block_hd {
    margin-left: 12px;
    text-align: center;
}
.wrap_title_menu_block_hd.is_child_true {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.title_menu_block_hd {
    font-size: 16px;
    color: #2f2a61;
    font-family: "gilroy", serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*icons hd*/


.mail_icon_hd {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.select_lang_icon_hd {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.info_icon_hd {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.logaut_icon_hd {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.setting_icon_hd {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.sheme_icon_hd {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}


.add_new_sys_icon_hd {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.struct_wrap_menu_child_blocks_hd {
    position: absolute;
    top: 72px;
    left: 0;
    z-index: 1200;
}

.wrap_menu_child_blocks_hd {



    margin-left: -50%;
    /*width: 100px;*/
    background: #e3e9f7;
    padding: 6px;


    box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;
    /*border: 2px solid #5E5AB9;*/
    box-shadow:
            /*-5px -5px 7px rgb(255 255 255 / 50%), */
    5px 5px 10px rgb(23 23 23 / 12%);


}

.wrap_menu_child_blocks_hd .wrap_title_menu_block_hd {
    margin-left: 0;
}