

#content_wrap_manometry.content_wrap {
    /*overflow-y: hidden;*/
    /*overflow-x: auto;*/

    display: block;
}

.wrap_render_indicators {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 20px;
}

.wrap_indicator_block {
    display: flex;
    justify-content: center;
}

.signature_indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: #5E5AB9;
    font-weight: 600;
    display: flex;
    justify-content: center;

}

.signature_indicator_block_text {
    position: relative;
}

.id_indicator {
    font-family: gilroy;
    color: #2f2a61;
}

.status_online_offline {
    display: block;
    position: absolute;
    top: 0;
    right: -10px;

    margin: auto;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

