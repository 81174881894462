

.wrap_title_block_look_manometrs_setting {
    display: flex;
    justify-content: center;
    padding: 24px 12px;
    border-bottom: 2px solid #2f2a61;
}


.is_two_column_all_column {
    display: flex;
    margin-top: 24px;
}

.title_checkbox_two_column {
    font-size: 14px;
    color: #2f2a61;
    margin-left: 12px;
}


.wrap_blocks_d_and_m_look_manometrs_setting {
    margin-top: 24px;

    display: flex;
    justify-content: space-between;
}



.wrap_block_desktop_look_manometrs_setting {
    flex: 1;
}

.wrap_block_mobile_look_manometrs_setting {
    flex: 1;
}

@media (max-width: 350px) {
    .wrap_blocks_d_and_m_look_manometrs_setting {
        flex-direction: column;
    }
    .wrap_block_mobile_look_manometrs_setting {
        margin-top: 24px;
    }
}

