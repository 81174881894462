
/*.wrap_header_bar_vertical {*/
/*    !*width: 100%;*!*/
/*    background: #5e5ab9;*/
/*    !*padding: 12px 24px;*!*/
/*    box-sizing: border-box;*/

/*    position: fixed;*/
/*    height: 100%;*/
/*    z-index: 1002;*/

/*    top: 0;*/

/*    border-right: 2px solid #2f2a61;*/
/*}*/

/*.wrap_tools_header {*/
/*    display: flex;*/
/*    !*align-items: center;*!*/
/*    flex-direction: column;*/

/*    position: relative;*/
/*}*/