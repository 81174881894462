.wrap_chart_pub {
    width: 100%;
    height: 800px;
    position: relative;
}

.wrap_vertical_lines {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.wrap_vertical_line_ {
    width: 100%;
    flex: 1;
    display: flex;
}

.vertical_line_chart {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    border: 1px solid #c1c6d2;

}

.horizontal_line_chart {
    flex: 1;
    height: 1px;
    background: #c1c6d2;

}

.wrap_horizontal_lines_chart {
display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
}