@media (max-width: 1000px) {
    .wrap_radials {
        flex-direction: column;
    }
    .wrap_radial_in_block_stat {
        width: 100%;
    }
}

@media (max-width: 950px) {
    .line_bottom_block_text {
        font-size: 16px;
    }


.wrap_id_text {

}

    .line_upper_block_text {
        font-size: 20px;

        max-width: 230px;
    }

    .title_oval {
        font-size: 20px;
    }
}


@media (max-width: 800px) {

    .content_wrap.wrap_statistic_block {

        /*padding-bottom: 0;*/
    }
    .wrap_radials {
        flex-direction: row;
    }
    .wrap_radial_in_block_stat {
        width: 49%;
    }
    .wrap_render_stat_blocks {
        flex-direction: column;
        box-sizing: border-box;
        padding: 0px 12px;
    }

    .wrap_statistic_blocks {
        width: 100%;
        box-sizing: border-box;
        margin: 0px;
        /*margin-top: 25px;*/
    }

    .wrap_id_text {
        font-size: 20px;
    }
}


@media (max-width: 600px) {
    .wrap_radials {
        flex-direction: row;
    }
    .wrap_radial_in_block_stat {
        flex-direction: column;
    }
    .content_wrap.wrap_statistic_block {
        box-shadow: none;
        padding: 0px;
        margin: 0;
        margin-top: 30px;
    }

    .wrap_render_stat_blocks {
        flex-direction: column;

    }

    .wrap_statistic_blocks {
        width: 100%;
        box-sizing: border-box;
        margin: 0px;
        /*margin-top: 25px;*/
    }

    .title_oval {
        font-size: 16px;
    }

    .line_upper_block_text {
        font-size: 16px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 300px;
    }
}


@media (max-width: 400px) {
    .wrap_fpos_text {
        margin-top: 12px;
        margin-left: 5px;
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .wrap_id_text {
        font-size: 16px;
        height: 35px;
    }
    .wrap_id_block {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .line_upper_block_text {
        font-size: 16px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
    }
}

@media (max-width: 300px) {





    .line_upper_block_text {
        font-size: 12px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    .title_oval{
        font-size: 12px;
    }

    .line_bottom_block_text {
        font-size: 10px;
    }
}








