
.wrap_slider {
    width: 100%;
    height: 100%;


    overflow-y: hidden;
    overflow-x: auto;

    display: flex;
}

.points_to_slider {
    display: flex;
    justify-content: center;

    position: absolute;
    top: -20px;
    left: 50%;

    z-index: 1000;

}

.wrap_slider_item {
    /*width: 500px;*/
    /*height: 500px;*/

    /*background: #f0ad4e;*/
    /*padding: 25px;*/
    box-sizing: border-box;
}

.wrap_slider_item.hidden_slide{
    /*display: none;*/

}

.wrap_slider_item.current_slide {
  /*display: block;*/
}

.test_slider_item {
    background: #5e5ab9;
}

.wrap_slider_idems {
    display: flex;
}

