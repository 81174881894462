.bg_gag {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 6546546;
    border-radius: 30px;

    top: 0;
    left: 0;
}

.gag_inside {
    position: relative;
    width: 100%;
    height: 100%;
}

.gag_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}