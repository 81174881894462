
/*header bar*/

.wrap_header_bar_vm {
    /*width: 100%;*/
    background: #5e5ab9;
    /*padding: 12px 24px;*/
    box-sizing: border-box;

    position: fixed;
    height: 100%;
    z-index: 1002;

    top: 0;

    border-right: 2px solid #2f2a61;

    width: 80%;
    transition: all 0.4s;
    overflow: hidden;
}

.wrap_tools_header_vm {
    display: flex;
    /*align-items: center;*/
    flex-direction: column;

    position: relative;

    height: 100%;
}

/*header logo and title*/

.wrap_logo_and_title {
    display: flex;
    align-items: center;

    border-bottom:2px solid #2f2a61;

    padding: 12px;
}

.wrap_header_logo_vm {
    /*width: 40px;*/
    /*height: 40px;*/
}

.header_logo_vm {
    width: 40px;
    height: 40px;
    transition: all 0.4s;
    /*width: 100%;*/
    /*height: 100%;*/
}

.wrap_header_title_vm {
    margin-left: 12px;
}

.header_title_vm {
    font-size: 18px;
    color: #EEEEEE;
    white-space: nowrap;
}

/*header content blocks*/

.wrap_menu_struct_blocks_vm{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.wrap_menu_blocks_vm {

}

.wrap_menu_hide_btn_vm {
    padding: 16px;
    font-size: 16px;
    color: #EEEEEE;
    font-family: "gilroy", serif;

    opacity: 1;
    transition: opacity 0.4s, background 0.4s;
    cursor: pointer;



    border-top: 2px solid #2f2a61;
}

.wrap_menu_hide_btn_vm:hover {
    opacity: 0.4;
}

.menu_hide_btn_vm {
    display: flex;

    align-items: center;
}

.wrap_menu_hide_btn_title_vm {
    margin-left: 12px;
    color: #EEEEEE;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu_hide_btn_title_vm {
    font-size: 18px;
    color: #EEEEEE;
    font-family: "gilroy", serif;
}


.wrap_icon_menu_hide_btn_vm {
    display: flex;
    align-items: center;
    justify-content: center;
}


/*menu block*/

.wrap_menu_block_vm {
    padding: 32px 16px;
    border-bottom: 1px solid #2f2a61;
    opacity: 1;
    transition: opacity 0.4s, background 0.4s;
    cursor: pointer;

}

.wrap_menu_block_vm:hover {
    opacity: 0.4;
    background: #9d9fbe;
}

.menu_block_content_vm {
    display: flex;
    align-items: center;

    position: relative;
}

.wrap_icon_menu_block_vm {
    display: flex;
    align-items: center;
    justify-content: center;

}



.wrap_title_menu_block_vm {
    margin-left: 12px;
    color: #EEEEEE;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title_menu_block_vm {
    font-size: 26px;
    font-weight: bold;
    color: #EEEEEE;
    font-family: "gilroy", serif;

}

.title_menu_block_vm.is_child_true {
    color: #f0ad4e;
}

/*icons vm*/


.mail_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}



.select_lang_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}

.setting_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}

.sheme_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}

.info_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}

.logaut_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}

.right_arr_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}


.left_arr_icon_vm {
    width: 42px;
    height: 42px;
    fill: #ffffff;
}

.bg_overlay_menu_vm {
    z-index: 1002;
    background: rgba(0,0,0,0.4);

    width: 100%;
    height: 100%;
    position: fixed;
}

.open_menu_child_icon_vm {
    position: absolute;

    width: 12px;
    height: 12px;

    fill: #EEEEEE;

    top: 6px;
    right: 0px;
    transition: transform 0.4s;
}

.open_menu_child_icon_vm.opened {
    transform: rotate(180deg);
}
