
.wrap_select_input {
    padding: 14px 16px;
}

.disabled_option  {
 opacity: 0.4;
}


.wrap_icon.input_select_icon {
    /*right: 16px;*/
    /*top: 18px;*/
    cursor: pointer;
}

.wrap_options {
    max-height: 100px;
    margin-top: 14px;
    /*border-top: 1px solid rgba(47, 42, 97, 0.12);*/
    overflow: auto;
    overflow-x: hidden;
}



.divider {
    height: 1px;
    background: rgba(47, 42, 97, 0.12);
    margin-top: 14px;
    /*margin-top: 7px;*/
}



.wrap_option_text {
    margin-top: 14px;
    cursor: pointer;
}

.wrap_option_text.first_child {
    margin-top: 0px;
}


.option_text {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #2F2A61;
}

.option_text:hover {
    color: #5E5AB9;
}

.option_text.selected_option {
    color: #5E5AB9;
}

.text_select_input {
    font-family: gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #2F2A61;
}

.text_select_input.placeholder {
    color: rgba(47, 42, 97, 0.4)
}


.div_input_select {
    /*height: 44px;*/
    /*margin: 0px 12px;*/
    background: transparent;
    color: #2F2A61;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    box-sizing: border-box;
    font-family: "Gilroy Semibold";
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.ellipsis_text_select {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}