

/*.lds_ring {*/
/*    display: inline-block;*/
/*    position: relative;*/
/*    width: 32px;*/
/*    height: 32px;*/
/*}*/
/*.lds_ring div {*/
/*    box-sizing: border-box;*/
/*    display: block;*/
/*    position: absolute;*/
/*    width: 80%;*/
/*    height: 80%;*/
/*    margin: 8px;*/
/*    border: 5px solid #5E5AB9;*/
/*    border-radius: 50%;*/
/*    animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;*/
/*    border-color: #5E5AB9 transparent transparent transparent;*/
/*}*/
/*.lds_ring div:nth-child(1) {*/
/*    animation-delay: -0.45s;*/
/*}*/
/*.lds_ring div:nth-child(2) {*/
/*    animation-delay: -0.30s;*/
/*}*/
/*.lds_ring div:nth-child(3) {*/
/*    animation-delay: -0.20s;*/
/*}*/
/*@keyframes lds_ring {*/
/*    0% {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/

.lds_ring {
    display: inline-block;
    width: 24px;
    height: 24px;
}
.lds_ring:after {
    content: " ";
    display: block;
    width: 80%;
    height: 80%;
    /*margin: 8px;*/
    border-radius: 50%;
    border: 4px solid  #5E5AB9;
    border-color:  #5E5AB9 transparent  #5E5AB9 transparent;
    animation: lds_ring 1.2s linear infinite;
}
@keyframes lds_ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
