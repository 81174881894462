
.mail_icon {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.select_lang_icon {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.info_icon {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.logaut_icon {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}