

.wrap_menu_block {
    padding: 12px;
}

.menu_block_content {
    display: flex;
    align-items: center;
}

.wrap_title_menu_block{
    margin-left: 12px;
}

.title_menu_block {
    font-size: 16px;
    color: #EEEEEE;
    font-family: "gilroy", serif;
}

.wrap_icon_menu_block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
}

.menu_hide_btn{
    padding: 12px;
    font-size: 16px;
    color: #EEEEEE;
    font-family: "gilroy", serif;
}

.wrap_menu_struct_blocks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}