.wrap_icon_btn {
    padding: 12px;
    opacity: 1;
    transition: all 0.4s;
    cursor: pointer;
}

.icon_btn {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap_icon_btn:hover {
    opacity: 0.5;
}

.wrap_lang_btn {
    padding: 12px;
    opacity: 1;
    transition: all 0.4s;
    cursor: pointer;
}

.wrap_lang_btn:hover {
    opacity: 0.5;
}

.lang_btn_text {
    font-size: 16px;
    color: #ffffff;
}