

.wrap_modal {
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    border-radius: 30px;
    margin: 15px;
    overflow: hidden;

    position: relative;


    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}

.wrap_modal_d {
    width: 500px;
    padding: 34px;
    box-sizing: border-box;
    max-height: 100%;
}

.wrap_modal.full_width {

    box-sizing: border-box;
    max-height: 100%;
    height: 100%;
    margin: 0;
    width: 100%;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


/*.wrap_modal_mob {*/
/*  width: 250px;*/
/*  padding: 24px;*/
/*}*/