

.swipe_block_over {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 939;
    /*background: #5e5ab9;*/
}