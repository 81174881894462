
.hide_scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hide_scroll::-webkit-scrollbar {
    display: none;
}

.options_scrollbar {
    scrollbar-color: #5e5ab9 #d7ddec;
    scrollbar-width: thin;
}

.options_scrollbar::-webkit-scrollbar {
    width: 6px;
    /*margin-top: 14px;*/
    /*margin-top: 14;*/
   height: 6px;
}

.options_scrollbar::-webkit-scrollbar-track {
    background-color: #d7ddec;
    border-radius: 12px;
}

.options_scrollbar::-webkit-scrollbar-thumb {
    background: #5e5ab9;
    border-radius: 12px;
}

.custom_scroll_container {
    width: 6px;
    background-color: #d7ddec;
    border-radius: 12px;
}

.custom_scroll_thumb {
    background: #5e5ab9;
    border-radius: 12px;
    width: 8px;
}

