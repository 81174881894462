
.overlay_clear {
    position: fixed;
    z-index: 2353464564566776;
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.overlay_children_position {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay_ {
    position: fixed;
    z-index: -1;
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}