

.wrap_input_ref_code {
    margin-top: 30px;

    display: flex;
    align-items: center;
    width: 100%;
}

.success_icon {
    width: 24px;
    height: 24px;
    margin-left: 12px;
    fill: #2F2A61;
    opacity: 0.6;
}