
.clickable_pub {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1220;
    cursor: pointer;
}

.fake_input{
    /*width: 100%;*/
    min-height: 52px;
    font-size: 19px;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    background: rgba(0, 0, 0, 0) linear-gradient(0deg, rgb(223, 230, 246), rgb(223, 230, 246)) repeat scroll 0% 0%;
    box-shadow: rgba(255, 255, 255, 0.698) -4px -5px 17px inset, rgba(0, 0, 0, 0.08) 6px 8px 19px inset;
}

.fake_input span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /*max-width: calc(100% - 80px);*/
    max-width: 100%;
}


.timezones_popup {
    z-index: 1220;
    max-height: 120px;
    overflow-y: auto;
    position: absolute;
    top: 65px;
    left: 0px;
    width: 100%;
    background: #E3E9F7;

    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%);
    /*background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
    /*box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
}


.tz_list_item {
    padding: 12px;
}

.tz_list_item:hover {
    background: #5E5AB9;
    color: #FFFFFF;
}

.tz_list_item.current {
    background: #5E5AB9;
font-weight: bold;
    color: #FFFFFF;
}

.hide_scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hide_scroll::-webkit-scrollbar {
    display: none;
}

.options_scrollbar {
    scrollbar-color: #5e5ab9 #d7ddec;
    scrollbar-width: thin;
}

.options_scrollbar::-webkit-scrollbar {
    width: 6px;
    /*margin-top: 14px;*/
    /*margin-top: 14;*/
    height: 6px;
}

.options_scrollbar::-webkit-scrollbar-track {
    background-color: #d7ddec;
    border-radius: 12px;
}

.options_scrollbar::-webkit-scrollbar-thumb {
    background: #5e5ab9;
    border-radius: 12px;
}

