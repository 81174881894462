

.bg_loader_chart {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    z-index: 6546546;
    border-radius: 2.4vh
}

.grid_inside_loader_chart {
    position: relative;
    width: 100%;
    height: 100%;

}

.content_loader_chart {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
}