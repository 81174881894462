

#keyboard_portal {
    width: 0;
}


.wrap_keyboard_block {
    position: fixed;
    bottom: 0;

    width: 100%;
    padding: 24px;

    background: linear-gradient(0deg,#e3e9f7,#e3e9f7);
    box-shadow: -5px -5px 14px hsl(0deg 0% 100% / 57%), 10px 10px 20px rgb(0 0 0 / 8%);

    box-sizing: border-box;
    z-index: 2222;
}

.wrap_manipulate_cursor_btns {
    display: flex;


}




.wrap_func_btns_row {
    display: flex;
}
.lr_cursor_btn_kb {
    flex: 1;

    /*padding: 10px;*/
    /*background: #5e5ab9;*/

    margin: 10px;
}

.btn_remove_symbol{
    flex: 1;

    /*padding: 10px;*/
    /*background: #5e5ab9;*/

    margin: 10px;
}

.am_pm_btn {
    flex: 1;

    /*padding: 10px;*/
    /*background: #5e5ab9;*/

    margin: 10px;
}

.am_pm_btn.disabled_ {
    opacity: 0.3;
}

.wrap_number_buttons {
    display: flex;

    flex-direction: column;
}

.num_btns_row {
    display: flex;
}

.keyboard_number_button {
    flex: 1;

    /*background: #f0ad4e;*/

    /*padding: 10px 5px;*/

    margin: 10px;
}


.purple_button_outer.lr_cursor_btn_kb {
    box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.6), 10px 11px 20px rgba(63, 60, 255, 0.2);
}

.text_keyboard_num_btn {
    font-size: 18px;
    color: #2f2a61;


}

.purple_button_text.text_keyboard_purple_btn {
    font-size: 18px;
    color: white;


}

@keyframes slide_keyboard_show {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}
@keyframes slide_keyboard_exit {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
}
