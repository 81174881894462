@media (max-width: 600px) {
    .content_wrap.wrap_table_pro {
        box-shadow: none;
        padding: 0px;
        margin: 0;
    }

    /*.wrap_render_stat_blocks {*/
    /*    flex-direction: column;*/
    /*}*/

    /*.wrap_statistic_blocks {*/
    /*    width: 100%;*/
    /*    box-sizing: border-box;*/
    /*    margin: 0px;*/
    /*    margin-top: 25px;*/
    /*}*/
}