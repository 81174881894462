

.wrap_header_hm {
    width: 100%;

    /*position: fixed;*/
    /*top: 0;*/


    display: flex;
    flex-direction: column;

    z-index: 1016;
}

.wrap_open_menu_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #5e5ab9;

    padding: 24px 16px;

    box-sizing: border-box;
}

.wrap_menu_blocks_hm {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.wrap_menu_block_hm {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #5e5ab9;
    padding: 30px 0px;
    margin-top: 5px;

    position: relative;
}

.wrap_menu_block_hm.is_child_true {
    opacity: 0.6;
}

.wrap_title_menu_block_hm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #EEEEEE;
    display: flex;
}

.title_menu_block_hm {
    font-size: 26px;
    font-weight: bold;
    color: #EEEEEE;
    font-family: "gilroy", serif;
}

.title_menu_block_hm.is_child_true {
    color: #ffffff;
}

.burger_menu_icon {
    width: 3em;
    height: 3em;
    fill: #ffffff;
}

.close_menu_icon{
    width: 3em;
    height: 3em;
    fill: #ffffff;
}

.open_menu_child_icon_hm {
    position: absolute;

    width: 24px;
    height: 24px;

    fill: #ffffff;

    top: 32px;
    right: 20px;
    transition: transform 0.4s;
}

.open_menu_child_icon_hm.opened {
    transform: rotate(180deg);
}

.system_name_hm {
    font-size: 26px;
  font-weight: bold;

    color: #ffffff;
}