

.lang_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lang_to_select_btn {
    margin-top: 30px;
    transition: transform 0.4s, box-shadow 0.4s;
}
.lang_to_select_btn:active {
    font-family: Gilroy semibold;
    box-shadow: none;
    transform: translate(-0.4vw, 0.4vw);
    transition: transform 0.4s, box-shadow 0.4s;
}

.wrap_select_lang {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    width: 65px;
}

.lang_to_select_btn.selected_lang {
    font-family: Gilroy semibold;
    box-shadow: none;
    transform: translate(-0.4vw, 0.4vw);
    transition: transform 0.4s, box-shadow 0.4s;
}