
.light_button.setting_chart_btn {
    padding: 12px;
}

.setting_chart_btn_icon {
    width: 24px;
    height: 24px;

    fill: #5e5ab9;
}


.fake_range_input_dates {
    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);
    border-radius: 12px;
    box-shadow: inset -4px -5px 17px rgb(255 255 255 / 70%), inset 6px 8px 19px rgb(0 0 0 / 8%);
    padding: 14px 16px;
    /*background: transparent;*/
    color: #2F2A61;
    outline: none;
    border: none;
    font-size: 24px;
    font-weight: 400;
    box-sizing: border-box;
    /*font-family: "Gilroy Semibold";*/
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.popper_block_datetime_picker {
    z-index: 1020;
    position: absolute;
    left: 0;
    top: 0px;

    box-sizing: border-box;
    padding: 12px;
    background: #E3E9F7;
    border-radius: 24px;
    box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.6), 10px 11px 20px rgba(63, 60, 255, 0.2);
}


.content_wrap.wrap_chart_block {
    flex-direction: column;
    position: relative;
    padding: 15px;
    /*overflow: hidden;*/
    box-sizing: border-box;
}



.wrap_selects_of_parametrs_chart {
    display: flex;
    margin-left: 0;
    width: 100%;
    align-items: flex-start;
    /*justify-content: flex-end;*/
}

.wrap_bottom_side {
    margin-top: 35px;
    display: flex;
    width: 100%;
}

.wrap_chart_parametrs_modal_content {
    flex: 1;

    margin-left: 24px;
    /*margin-left: 24px;*/
    padding-right: 24px;
    padding-bottom: 18px;

    overflow-y: auto;
}

.wrap_input_select_chart_block {
    flex: 1;
    display: flex;
    width: 100%;
}



.date_time_picker_popper-enter-active {
    animation: grow_animation .4s forwards;
}

.date_time_picker_popper-enter-done {
    /*animation: full_rotate_animation .8s infinite;*/
}

.date_time_picker_popper-exit-active {
    animation: grow_animation .4s reverse;
}


@keyframes grow_animation {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


@media (max-width: 800px) {
    .wrap_bottom_side {
        flex-direction: column;
        align-items: center;
    }

    .wrap_color_list{
        justify-content: center;
        margin-bottom: 12px;
    }

}

@media (max-width: 700px) {
    .fake_range_input_dates {
        display: flex;
        flex-direction: column;
    }

    .popper_block_datetime_picker {
        top: 100px;
        left: -100px;
    }

}

@media (max-width: 600px) {
    .wrap_bottom_side {
        flex-direction: row;
        align-items: center;
    }

    .wrap_color_list{
        margin-right: 12px;
        justify-content: space-between;
        margin-bottom: 0px;
    }

    .wrap_color_value{
        flex: 1;
        margin-top: 12px;
        margin-bottom: 0px;
    }

    .wrap_selects_of_parametrs_chart {
        flex-direction: column;
    }
}

