

.changing_month_block_wrap_list_item {
    margin-top: 10px;
    color: #5e5ab9;
    transition: color .4s, background .4s;
}

.changing_month_block_wrap_list_item.is_selected {
    color: #ffffff;
    background: #5e5ab9;
}

.changing_month_block {
    position: absolute;
    top: 35px;
    left: 0;

    width: 200px;

    background: linear-gradient(0deg,#e3e9f7,#e3e9f7);
    box-shadow: -5px -5px 14px hsl(0deg 0% 100% / 57%), 10px 10px 20px rgb(0 0 0 / 8%);
    border-radius: 16px;

    z-index: 1020;
    box-sizing: border-box;

    padding: 10px;
    padding-right: 5px;

}

.changing_month_block_wrap_list {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow-y: auto;

}

.changing_time_block {
    position: absolute;
    top: 35px;
    left: 0;

    width: 200px;

    background: linear-gradient(0deg,#e3e9f7,#e3e9f7);
    box-shadow: -5px -5px 14px hsl(0deg 0% 100% / 57%), 10px 10px 20px rgb(0 0 0 / 8%);
    border-radius: 16px;

    z-index: 1050;
    box-sizing: border-box;

    padding: 10px;
}

@media (max-width: 600px) {

    .changing_time_block {
        position: fixed;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
}

.change_month_btn {
    display: flex;
    padding: 5px 5px;

    align-items: center;
    transition: background-color 0.4s;
    cursor: pointer;
}

.wrap_week_days_names_calendar {
    display: flex;

    padding: 0px 32px;


}

.week_day_calendar {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 15px 5px;
}

.week_day_calendar_text {
    color: #5e5ab9;

    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 450px) {
    .week_day_calendar_text {


        font-size: 12px;

    }
}

.change_month_btn:hover {
    background-color: #83a992;
}

.wrap_calendar_block_with_arrows {
    display: flex;


}

.block_functional_calendar{

    margin-top: 24px;
    padding: 12px;
    background: linear-gradient(0deg,#e3e9f7,#e3e9f7);
    box-shadow: -5px -5px 14px hsl(0deg 0% 100% / 57%), 10px 10px 20px rgb(0 0 0 / 8%);
    border-radius: 16px;
}


.title_calendar_block {
    text-align: center;
    margin: 12px 0px;
}

.title_calendar_text {
    font-size: 30px;
    color: #2f2a61;
    font-weight: bold;

}

.wrap_title_calendar {
    margin: 0px;
}

.wrap_selects_month_year {
    display: flex;
    /*align-items: flex-start;*/
    justify-content: center;

    border-bottom: 1px solid #2f2a61;

    margin-bottom: 5px;
    /*margin-top: 30px;*/

    color: #5e5ab9;

    font-weight: bold;
    padding-bottom: 5px;
}

.title_calendar_full_date {
    margin-left: 10px;
}


.wrap_calendar_block {
    display: flex;
    flex-direction: column;

    flex: 1;



}

.wrap_week_days {
    display: flex;
}

.wrap_day {
    /*width: 30px;*/
    padding: 15px 5px;

    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    /*border-bottom: 1px solid;*/
    cursor: pointer;

    transition: background .4s;
    /*animation: showBlockPrev 0.2s  forwards;*/
}

.day_text {
    font-size: 14px;
    color: #2f2a61;
    transition: color .4s;
}

.wrap_day.selected_day {
    background: #5e5ab9;
}

.day_text.selected_day {

    color: #ffffff;
}

.wrap_day.disabled_day {
    opacity: 0.3;
}

.wrap_day.not_is_curr_month {
    /*background: ;*/
    opacity: 0.4;
}

.wrap_day.not_is_curr_month.selected_day {
    opacity: 1;
    background: #5e5ab9;
}



@keyframes showBlockPrev {
    0% {
        transform: translateX(-100%);
        /*margin-left: -100%;*/
        /*width: 0px;*/
    }
    100% {
        transform: translateX(0);

        /*width: 30px;*/
        /*margin-left: 0px;*/
    }
}

@keyframes showBlockCurr {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes showBlockNext {
    0% {
        transform: translateX(100%);

        /*width: 0px;*/
        /*margin-left: 100%;*/
    }
    100% {
        /*width: 30px;*/
        /*margin-left: 0;*/
        transform: translateX(0);
    }
}