

.wrap_title_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.title_modal_text {
    font-family: 'gilroy', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #2F2A61;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}