.image-slider img {
    width: 200px;
    position: absolute;
}

.image-slider button {
    background-color: orange;
}

.image-slider {
    display: flex;
    align-content: center;
    margin-top: 10px;
    justify-content: center;
}

.image-wrapper {
    position: relative;
    text-align: left;
    width: 200px;
    height: 150px;
    overflow: hidden;
}

.slide-right-enter {
    transform: translateX(100%);
}

.slide-right-enter-active {
    transform: translateX(0%);
    transition: transform 1000ms ease-in-out;
}

.slide-right-exit {
    transform: translateX(0%);
}

.slide-right-exit-active {
    transform: translateX(-100%);
    transition: transform 1000ms ease-in-out;
}

.slide-left-enter {
    transform: translateX(-100%);
}

.slide-left-enter-active {
    transform: translateX(0%);
    transition: transform 1000ms ease-in-out;
}

.slide-left-exit {
    transform: translateX(0%);
}

.slide-left-exit-active {
    transform: translateX(100%);
    transition: transform 1000ms ease-in-out;
}