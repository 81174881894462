
.wrap_radials {
    margin-top: 12px;
}

.wrap_linears_and_radials {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
}

.title_oval {
    font-weight: 600;
    font-size: 24px;
    color: #2f2a61;

    font-family: "gilroy", serif;
}

.wrap_linears {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
}

.wrap_radials {
    display: flex;
    justify-content: space-between;
}

.wrap_radial_in_block_stat {
    /*flex-direction: column;*/
    /*flex: 1;*/
    width: 49%;
    justify-content: space-between;
}

.wrap_r1  {
    /*flex:1;*/
    display: flex;
    align-items: center;
}

.wrap_r2  {
    /*flex:1;*/
    display: flex;
    align-items: center;
}

.wrap_r3  {
    /*flex:1;*/
    display: flex;
}



.content_wrap.wrap_statistic_block {
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    box-shadow: -0.5vh -0.5vh 1.4vh rgba(255, 255, 255, 0.57), 1vh 1vh 2vh rgba(0, 0, 0, 0.083);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: default;
    box-sizing: border-box;
    margin-top: 30px;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 50px;
    padding-top: 70px;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 15px;
    min-height: 200px;
    position: relative;
}

.wrap_render_stat_blocks {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 1;
    box-sizing: border-box;
    padding: 0px 15px;
    /*margin-top: 15px;*/
}

.statistic_blocks_container {
    box-sizing: border-box;
    /*margin: 0px 20px;*/
    /*padding-bottom: 12px;*/
}

.wrap_statistic_blocks {
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    height: 100%;
    /*flex: 1;*/
    /*margin: 15px;*/
    /*margin: 15px;*/
    /*margin-top: 15px;*/

    min-height: 300px;
    /*flex: 1;*/
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    box-shadow: -0.5vh -0.5vh 1.4vh rgba(255, 255, 255, 0.57), 1vh 1vh 2vh rgba(0, 0, 0, 0.083);
    border-radius: 24px;
    /*display: flex;*/
    justify-content: center;
    cursor: default;
    position: relative;
    box-sizing: border-box;
}

.wrap_id_block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /*align-items: center;*/
}
.wrap_id_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    min-width: 122px;
    min-height: 29px;
    color: #FFFFFF;
    border-radius: 24px 0 24px 0;
    padding: 0px 16px;
    white-space: nowrap;
    height: 55px;
}

.wrap_fpos_text {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;

    font-family: gilroy;
    font-weight: 600;
    color: #aab5c3;

    margin-left: 5px;
    text-align: end;
}




.wrap_line_block {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content;
    height: 100%;
    gap: 12px;
    margin-top: 35px;
}

.line_upper_block {
    display: flex;
    /* grid-template-columns: max-content 1fr; */
    justify-content: space-between;
    align-items: center;
}

.line_upper_block_text {
    color: #2F2A61;

    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: normal;
    text-align: right;
}

.line_upper_block_text:first-child {
    font-weight: 700;
    text-align: left;
}

.line_bottom_block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-content: center;
}

.line_bottom_block_text {
    color: #2F2A61;

    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: normal;
    text-align: right;
    opacity: 0.4;
}

.line_bottom_block_text:first-child {
    text-align: left;
}



/*div.line_block div.redline, div.line_block div.blueline {*/
/*    display: grid;*/
/*    align-items: center;*/
/*    width: 99%;*/
/*    height: 100%;*/
/*    border-radius: 1.4vh;*/
/*    border: .6vh solid #eff3fb;*/
/*    box-shadow: 0 .4vh 1vh rgba(0, 0, 0, .0381884), -.3vh -.5vh .8vh rgba(255, 255, 255, .375546);*/
/*    justify-items: end;*/
/*    z-index: 500;*/
/*    overflow: hidden;*/
/*}*/

/*div.line_block div.redline {*/
/*    background: linear-gradient(217.76deg, #FF4D4D 3.07%, #F4713D 81.8%);*/
/*}*/

/*div.line_block div.blueline {*/
/*    background: linear-gradient(217.76deg, #17C8FF 3.07%, #7EE6FF 81.8%);*/
/*}*/

/*div.line_block div.line {*/
/*    display: block;*/
/*    width: 101%;*/
/*    height: 120%;*/
/*    transition: all 0.6s;*/
/*    background-color: #EFF3FB;*/
/*    margin-left: -2px;*/
/*    margin-right: -2px;*/
/*}*/





