

.disabled_acc_btn {
    opacity: 0.2;
}

.wrap_actions_btns_modal {
    display: flex;
}

.accept_btn {
    flex:1;
    /*background: #5E5AB9;*/
    /*box-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.2);*/
    border: none;
    border-radius: 12px;
    /*padding: 13px 10px;*/
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.accept_btn:active {

    box-shadow: none;
    transition: box-shadow 0.4s;
}

.text_for_acc_btn {
    font-family: gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
}

.cancel_btn {
    flex:1;
    /*background: #E3E9F7;*/
    /*box-shadow: -5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12);*/
    border-radius: 12px;
    border: none;
    /*padding: 13px 10px;*/
    cursor: pointer;

}

.cancel_btn:active {
    /*background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
    /*box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
    /*transition: all 0.4s;*/
}

.text_for_canc_btn {
    font-family: gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #2F2A61;
}



.mr_modal_btn {
    margin-right: 10.5px;
}

.ml_modal_btn {
    margin-left: 10.5px;
}