

.clock_input {
    padding: 10px 25px;
    margin-top: 30px;

    font-size: 18px;
}

.label_checkbox_time_type {
  margin-left: 10px;
}

.full_custom_input.time_input_style {
    width: 100%;
    padding: 13px 0 14px;
    background: transparent;
    color: #2F2A61;
    outline: none;
    border: none;
    box-sizing: border-box;

    font-family: 'gilroy', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #2F2A61;
    border-radius: 12px;
}

.full_custom_input.time_input_style.focused {
    border: none;
}