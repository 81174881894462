

.wrap_input {

}

.wrap_icon_input {
    cursor: pointer;
    color: #2F2A61;
}

.wrap_input.with_errs {
    padding-bottom: 10px;
}

.text_area_style {
    background: transparent;
    margin: 0px 12px;
    color: #2F2A61;
    outline: none;
    border: none;
    box-sizing: border-box;
    font-family: gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #2F2A61;
    resize: none;
}


.regist_input_style {
    margin: 0px 16px;
    background: transparent;
    color: #2F2A61;
    outline: none;
    border: none;
    box-sizing: border-box;

    font-family: 'gilroy', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #2F2A61;
}

.text_area_style::placeholder, .regist_input_style::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(47, 42, 97, 0.4);
    opacity: 1; /* Firefox */
}

.text_area_style:-ms-input-placeholder, .regist_input_style:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(47, 42, 97, 0.4);
}

.text_area_style::-ms-input-placeholder, .regist_input_style::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(47, 42, 97, 0.4);
}

.regist_input_style.ref_cod_bg {
    background: #9d9fbe;

    box-shadow:
            inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);
}

.pd_inp_with_eye {
    padding: 13px 30px 14px 0px;
}


.pd_inp {
    padding-top: 13px;
    padding-bottom: 14px;
    padding-left: 0px;
    padding-right: 0px;
}