

/*!* div#time_work:hover{opacity: 0.8;}*/
/*div#icobar{*/
/*    position: absolute;*/
/*    right: 0;*/
/*    top: 0;*/
/*    width: 150px;*/
/*    padding-bottom: 5px;*/
/*    height: 50px;*/

/*    background-color: #EFF3FB;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    display: flex;*/
/*    grid-template-columns: 1fr;*/
/*    grid-template-rows: 1fr 1fr;*/
/*    justify-items: center;*/
/*    padding-right: 10px;*/
/*    padding-top: 5px;*/
/*    border-radius: 0px 0px 0px 20px;*/
/*    global_wrappers-shadow: -5px 10px 14px rgb(0 0 0 / 6%);*/
/*}*/

/*div#icobar::after, div#icobar::before*/

/*{*/

/*    width: 80px;*/
/*    height: 35px;*/
/*    position: absolute;*/
/*    content: '';*/
/*    top: -14px;*/
/*    left: -44px;*/
/*    background: #EFF3FB;*/
/*    z-index: -1;*/
/*    transform: rotate(*/
/*60deg*/
/*);*/
/*    global_wrappers-shadow: 5px 10px 14px rgb(0 0 0 / 6%);*/
/*}*/

/*div#icobar::before {*/
/*    z-index: 1015;*/
/*    global_wrappers-shadow: none;*/

/*}*/

/*.statuses_batt{*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*    margin-right: 5px;*/
/*}*/


/*div#icobar div {*/
/*    display: flex;*/
/*    height: 25px;*/
/*    width: 100%*/
/*}*/
/*div#icobar div svg{height: inherit;}*/
/*div#icobar div ui {*/

/*    font-size: 15px;*/
/*   */
/*    align-content: end;*/
/*}*/
/*#stat_bar { */
/*  display: flex;*/
/*  flex-direction: column;*/
/*} *!*/

/*.main_container {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin-top: 20px;*/
/*}*/

/*.hover_shadow:hover {*/
/*    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);*/
/*    padding: 10px;*/
/*}*/

/*.MuiFormControl-root.MuiInputBase-input {*/
/*    !*padding: 0 !important;*!*/
/*}*/

/*.MuiDialog-paperWidthSm, .MuiDialog-paperWidthXs {*/
/*    border-radius: 24px !important;*/
/*}*/

/*#modalka::-webkit-scrollbar, #modalka {*/
/*    width: 0px; !* ширина scrollbar'a *!*/
/*    background: transparent;*/
/*    -ms-overflow-style: none; !* IE 10+ *!*/
/*    scrollbar-width: none;*/
/*}*/

/*.PrivatePickersToolbar-root {*/
/*    !*display:none !important;*!*/
/*}*/

/*.MuiDialogContent-root.MuiTabs-root {*/
/*    !*display: flex !important;*!*/
/*}*/

/*.wrap_inp_dt input {*/
/*    padding: 0;*/
/*    cursor: pointer;*/
/*    height: initial;*/
/*    width: 130px;*/
/*}*/

/*.MuiPaper-rounded.MuiPaper-root {*/
/*    background: #e3e9f7;*/
/*    border-radius: 12px;*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7) !important;*/
/*    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%) !important;*/
/*    !*min-height: 425px;*!;*/
/*}*/

/*.MuiDialogContent-root:first-of-type {*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6) !important;*/
/*    overflow-y: hidden !important;*/

/*    color: #2F2A61 !important;*/
/*}*/

/*.marg_all_not_1c {*/
/*    margin-left: 10px;*/
/*}*/

/*.marg_all_not_1c:first-child {*/
/*    margin-left: 0px;*/
/*}*/

/*.flex_ {*/
/*    display: flex;*/
/*}*/

/*.close_btn {*/
/*    color: #2F2A61;*/
/*    width: 100%;*/
/*    padding: 10px 5px;*/
/*    font-size: 14px;*/
/*    background: linear-gradient(*/
/*            0deg, #E3E9F7, #E3E9F7);*/
/*    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%);*/
/*    font-family: gilroy;*/
/*    font-weight: bold;*/
/*    margin-left: 10px;*/
/*    border-radius: 20px;*/
/*    margin-bottom: 25px;*/
/*    text-transform: none;*/
/*}*/


/*.MuiPickersDay-root.Mui-selected {*/
/*    background: #5E5AB9 !important;*/
/*    width: 30px !important;*/
/*    height: 30px !important;*/
/*}*/

/*.MuiPickersDay-hiddenDaySpacingFiller {*/
/*    width: 30px !important;*/
/*    height: 30px !important;*/
/*}*/

/*.MuiDialogActions-root {*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6) !important;*/
/*}*/

/*.MuiInput-underline:after {*/
/*    border-bottom: none !important;*/
/*}*/

/*.MuiPickersDay-root.Mui-selected:hover {*/
/*    background: #5E5AB9 !important;*/
/*    box-shadow: none !important;*/
/*}*/

/*.MuiButtonBase-root.MuiPickersDay-root {*/
/*    margin: 0 5px !important;*/
/*    width: 30px !important;*/
/*    height: 30px !important;*/
/*}*/

/*.MuiPickersDay-root {*/
/*    margin: 0 5px !important;*/
/*}*/

/*.css-mvmu1r {*/
/*    margin: 10px 0 !important;*/
/*}*/


/*.wrap_date_picker.MuiInputAdornment-root {*/
/*    display: none !important;*/
/*}*/

/*.MuiButtonBase-root.MuiPickersDay-root {*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7) !important;*/
/*    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%) !important;*/
/*    transition: all 0.4s !important;*/
/*}*/

/*.MuiButtonBase-root.MuiPickersDay-root:hover {*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6) !important;*/
/*    box-shadow: inset -4px -5px 17px rgb(255 255 255 / 70%), inset 6px 8px 19px rgb(0 0 0 / 8%) !important;*/
/*    transition: all 0.4s !important;*/
/*}*/

/*.MuiButtonBase-root.MuiPickersDay-root {*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7) !important;*/
/*    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%) !important;*/
/*    transition: all 0.4s;*/
/*}*/

/*.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {*/
/*    background: #5E5AB9 !important;*/

/*}*/


/*.MuiButtonBase-root.MuiPickersDay-root:hover {*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6) !important;*/
/*    box-shadow: inset -4px -5px 17px rgb(255 255 255 / 70%), inset 6px 8px 19px rgb(0 0 0 / 8%) !important;*/
/*    transition: all 0.4s;*/
/*}*/

/*.MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected) {*/
/*    !*border: 1px solid rgba(0, 0, 0, 0.6);*!*/
/*}*/

/*.MuiDialogActions-root {*/
/*    flex-direction: row-reverse;*/
/*}*/

/*.MuiDialogActions-root > :first-of-type {*/
/*    color: #2F2A61;*/
/*    flex: 1;*/
/*    padding: 10px 5px;*/
/*    font-size: 14px;*/
/*    background: linear-gradient(*/
/*            0deg, #E3E9F7, #E3E9F7);*/
/*    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%);*/
/*    font-family: gilroy;*/
/*    font-weight: bold;*/

/*    border-radius: 20px;*/
/*    margin-left: 5px;*/
/*    text-transform: none;*/
/*}*/

/*.MuiDialogActions-root > :not(:first-of-type) {*/
/*    color: #fff !important;*/
/*    !*width: 100%;*!*/
/*    flex: 1;*/
/*    margin-right: 5px;*/
/*    padding: 10px 0px;*/
/*    font-size: 14px;*/
/*    box-shadow: -9px -9px 16px rgb(255 255 255 / 60%), 4px 4px 8px rgb(63 60 255 / 20%);*/
/*    margin-left: 0px !important;*/
/*    font-family: gilroy;*/
/*    font-weight: bold;*/
/*    border-radius: 20px;*/
/*    text-transform: none;*/
/*    background-color: #57559a;*/
/*}*/

/*.MuiDialogActions-root > :not(:first-of-type):hover {*/
/*    box-shadow: none;*/
/*    background-color: #5e5ab9;*/
/*}*/


/*.id_modal {*/
/*    position: absolute;*/
/*    display: grid;*/
/*    min-width: 65px;*/
/*    width: auto;*/
/*    height: 15px;*/
/*    background-color: #5E5AB9;*/
/*    top: 0;*/
/*    left: 0;*/
/*    color: #ffffff;*/
/*    font-size: 20px;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    padding: 13px 16px;*/
/*    text-overflow: ellipsis;*/
/*    border-radius: 2.4vh 0 2.4vh 0;*/
/*    z-index: 1002;*/
/*    align-content: center;*/
/*    font-weight: 600;*/
/*    justify-content: center;*/
/*}*/



/*.wh_sp_no_wr {*/
/*    white-space: nowrap;*/
/*}*/



/*.fw_norm {*/
/*    font-weight: normal;*/
/*}*/

/*.notif_report {*/
/*    !* display: none; *!*/
/*    width: 250px;*/
/*    padding: 20px 15px;*/
/*    position: fixed;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);*/
/*    border-radius: 12px;*/
/*    text-align: center;*/
/*    z-index: 100000000000;*/
/*}*/


/*.theme_inp {*/
/*    margin-top: 20px;*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*    border: none;*/
/*    padding-left: 16px;*/

/*    padding-top: 14px;*/
/*    padding-bottom: 14px;*/
/*    color: #9d9fbe;*/
/*    font-size: 24px;*/

/*}*/

/*.theme_inp:focus {*/
/*    border: none;*/
/*    outline: none;*/
/*    color: #2F2A61;*/

/*}*/

/*.text_report_notif {*/
/*    color: #2F2A61;*/

/*    font-weight: 600;*/
/*    font-size: 20px;*/


/*}*/

/*.notif_ok_btn {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    background: #5E5AB9;*/
/*    box-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.12);*/
/*    border-radius: 12px;*/
/*    width: 100%;*/
/*    transition: all 0.4s;*/
/*    height: 40px;*/
/*    color: white;*/
/*    font-size: 20px;*/
/*    margin-top: 20px;*/
/*}*/

/*.notif_ok_btn:active {*/
/*    background: linear-gradient(0deg, #5E5AB9, #5E5AB9);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.1), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*}*/


/*.wrap_report {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-top: 25px;*/
/*    justify-content: center;*/
/*    align-items: center;*/

/*}*/

/*.wrap_inputs {*/
/*    width: 100%;*/
/*    box-sizing: border-box;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.report {*/
/*    width: 90%;*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-top: 25px;*/
/*    !* justify-content: space-between; *!*/
/*    align-items: center;*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);*/
/*    !* global_wrappers-shadow: -9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595); *!*/
/*    border-radius: 30px;*/
/*    margin-bottom: 50px;*/
/*}*/

/*.sel_inp {*/
/*    color: #2F2A61;*/

/*    font-weight: 600;*/
/*    font-size: 24px;*/
/*    padding-right: 44px;*/
/*    position: relative;*/
/*}*/

/*.err_text {*/
/*    color: #fd6737;*/
/*    display: none;*/
/*    font-weight: 600;*/
/*    font-size: 15px;*/

/*    margin-left: 16px;*/
/*    margin-top: 2px;*/
/*    bottom: 0px;*/
/*    left: 0px;*/
/*}*/

/*.arrow_rep {*/

/*    position: absolute;*/
/*    right: 22px;*/
/*    fill: #5E5AB9;*/
/*    top: 14px;*/
/*}*/

/*.arrow_rep_d {*/

/*}*/

/*.arrow_rep_up {*/
/*    display: none;*/
/*}*/

/*.tr_sel {*/
/*    position: absolute;*/
/*    right: 5px;*/
/*    top: 5px;*/
/*}*/

/*.flex_wrap_yes {*/
/*    flex-wrap: wrap;*/
/*}*/

/*.option_thema {*/
/*    padding-top: 14px;*/
/*    cursor: pointer;*/
/*}*/


/*.options_themes {*/
/*    display: none;*/

/*    flex-direction: column;*/
/*    color: #2F2A61;*/

/*    font-weight: 600;*/
/*    font-size: 24px;*/
/*    overflow-y: auto;*/
/*    max-height: 168px;*/
/*    transition: all 0.4s;*/
/*    scrollbar-width: thin;*/
/*    scrollbar-color: #5e5ab9 #E3EDF7;*/

/*}*/

/*.options_themes::-webkit-scrollbar {*/
/*    width: 8px;*/
/*    !* width of the entire scrollbar *!*/
/*}*/

/*.options_themes::-webkit-scrollbar-track {*/
/*    background: #E3EDF7;*/
/*    border-radius: 12px;*/
/*    box-shadow: inset -3px -3px 7px #ffffff, inset 3px 3px 0px #d5e1ec; !* color of the tracking area *!*/
/*}*/

/*.options_themes::-webkit-scrollbar-thumb {*/
/*    background-color: #5e5ab9;*/
/*    border-radius: 12px;*/

/*}*/

/*.wrap_select {*/
/*    position: relative;*/

/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*    padding-top: 14px;*/
/*    padding-bottom: 14px;*/
/*    padding-left: 16px;*/
/*    !* padding-right: 44px; *!*/

/*}*/

/*.wrap_sel_block {*/
/*    width: 100%;*/
/*    !* height: 56px; *!*/
/*    margin-top: 24px;*/
/*}*/

/*.contact_with_me_inp {*/
/*    margin-top: 20px;*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*    border: none;*/
/*    padding-left: 16px;*/

/*    padding-top: 14px;*/
/*    padding-bottom: 14px;*/
/*    color: #9d9fbe;*/
/*    font-size: 24px;*/

/*}*/

/*.contact_with_me_inp:focus {*/
/*    border: none;*/
/*    outline: none;*/
/*    color: #2F2A61;*/

/*}*/

/*.your_name_inp {*/
/*    margin-top: 20px;*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*    border: none;*/
/*    padding-left: 16px;*/

/*    padding-top: 14px;*/
/*    padding-bottom: 14px;*/
/*    color: #9d9fbe;*/
/*    font-size: 24px;*/

/*}*/


/*.your_name_inp:focus {*/
/*    border: none;*/
/*    outline: none;*/
/*    color: #2F2A61;*/

/*}*/

/*.your_msg_inp {*/
/*    margin-top: 40px;*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*    border: none;*/
/*    padding-left: 16px;*/

/*    padding-top: 14px;*/
/*    padding-bottom: 14px;*/
/*    color: #9d9fbe;*/
/*    font-size: 24px;*/
/*    resize: none;*/
/*}*/

/*.report_title {*/
/*    color: #2F2A61;*/
/*    font-style: normal;*/
/*    font-size: 30px;*/
/*    font-weight: 600;*/
/*}*/

/*.your_msg_inp:focus {*/
/*    border: none;*/
/*    outline: none;*/
/*    color: #2F2A61;*/

/*}*/

/*.wrap_setting_frame {*/
/*    display: none;*/
/*    !* flex-direction: column; *!*/
/*    margin-top: 25px;*/
/*    justify-content: center;*/
/*    align-items: center;*/

/*}*/


.setting_frame {
    width: 100%;
    margin-top: 0px;
    box-sizing: border-box;
}

/*.header_title_block_sett {*/
/*    position: absolute;*/
/*    display: grid;*/
/*    min-width: 122px;*/
/*    width: auto;*/
/*    height: 29px;*/
/*    background-color: #5E5AB9;*/
/*    top: 0;*/
/*    left: 0;*/
/*    color: #ffffff;*/
/*    font-size: 24px;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    padding: 13px 16px;*/
/*    text-overflow: ellipsis;*/
/*    border-radius: 2.4vh 0 2.4vh 0;*/
/*    z-index: 700;*/
/*    align-content: center;*/
/*    font-weight: 600;*/
/*    justify-content: center;*/
/*}*/

.air_cu_btns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/*.regen_t_inp {*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*    border: none;*/
/*    padding-left: 16px;*/
/*    padding-right: 16px;*/
/*    padding-top: 14px;*/
/*    padding-bottom: 14px;*/
/*    color: #9d9fbe;*/
/*    font-size: 24px;*/
/*    width: 65px;*/
/*}*/


/*!* INPUT[type="text"]  {*/
/*    color: #9d9fbe;*/
/*}*/

/*INPUT[type="text"]:focus  {*/
/*    color: #2F2A61;*/
/*} *!*/
/*.regen_t_inp:focus {*/
/*    border: none;*/
/*    outline: none;*/
/*    color: #2F2A61;*/

/*}*/

/*.regen_t_inp::placeholder {*/

/*    font-size: 24px;*/
/*}*/

.regen_time {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.week_day_btn {
    /*width: 12%;*/
    flex:1;
    min-width: 36px;
    /*padding-left: 2px;*/
    font-weight: 600;
    /*height: 36px;*/
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 5px;
    background: #E3E9F7;
    box-shadow: -5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12);
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    color: #2F2A61;
    transition: all 0.4s;
    cursor: pointer;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.week_day_btn:active {
    background: transparent;
    box-shadow: none;
    transform: translate(-0.4vw, 0.4vw);
}
/*.text_btn {*/
/*    color: rgb(166, 177, 191);*/
/*    font-weight: bold;*/
/*    font-size: 20px;*/
/*    transition: color 0.4s;*/
/*}*/

/*.text_btn:hover {*/
/*    color: #5E5AB9;*/
/*}*/

/*.setts_blocks_border {*/
/*    border-bottom: 1px solid #d3d7e9;*/
/*}*/

.ta_end {
    text-align: end;
}

.pt_20 {
    padding-top: 20px;
}

.pb_20 {
    padding-bottom: 20px;
}


.pt_16 {
    padding-top: 16px;
}

.pb_16 {
    padding-bottom: 16px;
}

.week_day_btn_active {
    background: transparent;
    box-shadow: none;
    /*background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
    /*global_wrappers-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 4px 4px 17px rgba(0, 0, 0, 0.08);*/
    transform: translate(-0.4vw, 0.4vw);
}

.wrap_blocks_settings {
    width: 98%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    /* global_wrappers-shadow: -9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595); */
    /*border-radius: 30px;*/
    /* padding-top: 61px; */
}

/*.range_days_bef_regen {*/
/*    margin-top: 15px;*/
/*}*/

/*.days_bef_regen_tit {*/
/*    margin-bottom: 15px;*/
/*}*/

.wrap_diapason {
    /* display: flex; */
    display: none;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2F2A61;
    opacity: 0.4;
}

.range_polz {
    width: 100%;
    display: none;
}

.setting_block {
    width: 100%;
    border-bottom: 1px solid #d3d7e9;
    padding-bottom: 20px;
    padding-top: 20px;
}

.color_purple {
    color: #5e5ab9;
}

/*!* input[type=range] {*/
/*    -webkit-appearance: none;*/
/*  }*/
/*  input[type=range]::-webkit-slider-thumb {*/
/*    -webkit-appearance: none;*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    background: url("./Rectangle.svg");*/
/*  } *!*/

.title_sett_block {
    color: #2F2A61;
    font-style: normal;
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    max-width: calc(100% - 80px);
}


.wrap_tit_switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rad_btn_bord {
    background: linear-gradient(
            -244deg, #d0d8e9, #ffffff 80%);
    border-radius: 50%;
    transition: all 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
}

.in_rad_btn_bord {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #E3EDF7;
    box-shadow: inset -3px -3px 7px #FFFFFF, inset 3px 3px 0px #d5e1ec;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrap_rad-btn {
    display: flex;

    align-items: center;
    margin-top: 16px;
}

.label_to_rad_btn {
    color: #2F2A61;
    font-style: normal;
    font-size: 20px;
    font-weight: 600;
    margin-left: 15px;
}

.checked_rad_btn_bord {
    display: none;
    width: 22px;
    height: 22px;
    background: #5E5AB9;
    box-shadow: 2px 2px 8px rgba(136, 165, 191, 0.28);
    border-radius: 50%;
}


.ok_cnc_btns {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 25px;
}


.ok_sett_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5E5AB9;
    box-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.12);
    border-radius: 12px;
    width: 35%;
    transition: all 0.4s;
    height: 50px;
    color: white;
}

.ok_sett_btn:active {
    background: linear-gradient(0deg, #5E5AB9, #5E5AB9);
    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.1), inset 6px 8px 19px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.canc_sett_btn {
    transition: all 0.4s;
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    box-shadow: -9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595);
    border-radius: 12px;
    width: 35%;
    height: 50px;
    color: #2F2A61;
    cursor: pointer;
}

.home_chart_modal_btn {
    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%) ;
    border-radius: 12px;
    text-align: center;
    display: block;
    font-size: 20px;
    color: #2F2A61;
    cursor: pointer;
    transition: all 0.4s;
    white-space: nowrap;
    padding: 10px 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
}

/*.home_chart_modal_btn {*/
/*    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%) ;*/
/*    border-radius: 12px;*/
/*    text-align: center;*/
/*    display: block;*/
/*    color: #2F2A61;*/
/*    cursor: pointer;*/
/*    transition: all 0.4s;*/
/*    white-space: nowrap;*/
/*    padding: 10px 20px;*/
/*    text-overflow: ellipsis;*/
/*    overflow: hidden;*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);*/
/*}*/

/*.canc_sett_btn:active {*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*    background: linear-gradient(*/
/*            0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgb(255 255 255 / 70%), inset 4px 4px 17px rgb(0 0 0 / 8%);*/
/*}*/

.home_chart_modal_btn.canc_sett_btn_active {
    transform: translate(-0.4vw, 0.4vw);
    background: none;
    box-shadow: none;
}

/*.ta_center {*/
/*    text-align: center;*/
/*}*/

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #DEE8F2;
    box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
    -webkit-transition: .4s;
    transition: .4s;
}

.flex_jc_center {
    justify-content: center;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background: linear-gradient(129.58deg, #E6EEF8 0%, #FFFFFF 111.48%);
    -webkit-transition: .4s;
    transition: .4s;
}

.ml25 {
    margin-left: 25px;
}

.mr25 {
    margin-right: 25px;
}

input:checked + .slider {
    background-color: #5E5AB9;
}

input:focus + .slider {
    /* global_wrappers-shadow: 0 0 1px #5E5AB9;; */
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.wrap_switch {
    width: 62px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    background: linear-gradient(
            -244deg, #d0d8e9, #ffffff 80%);
}


.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/*#overlay_canvas {*/
/*    position: fixed; !* Sit on top of the page content *!*/
/*    display: none;*/

/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    z-index: 10000;*/
/*}*/

/*.overlayPX {*/
/*    width: 25px;*/
/*    height: 25px;*/

/*    position: absolute;*/
/*    transition: all 0.5s;*/
/*}*/

/*.skip_next {*/
/*    position: fixed;*/
/*    display: none;*/
/*    justify-content: space-between;*/
/*    bottom: 50px;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    z-index: 10000000;*/
/*}*/

/*.skip_interaktive {*/
/*    !* position: fixed; *!*/
/*    display: flex;*/
/*    z-index: 10000000;*/
/*    width: 80px;*/
/*    height: 40px;*/

/*    align-items: center;*/
/*    justify-content: center;*/

/*    transition: all 0.4s;*/
/*    color: #2F2A61;*/
/*    border-radius: 12px;*/
/*    !* padding: 5px 20px; *!*/
/*    cursor: pointer;*/
/*    !* opacity: 0.8; *!*/
/*    background: #E3E9F7;*/
/*    !* global_wrappers-shadow: -5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12); *!*/
/*    border-radius: 12px;*/
/*    margin-left: 25px;*/
/*    font-size: 20px;*/
/*}*/

/*.hide {*/
/*    display: none;*/
/*}*/

/*.skip_interaktive:active {*/
/*    box-shadow: none;*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*}*/


/*.next_move {*/
/*    display: flex;*/
/*    z-index: 10000000;*/
/*    right: 5%;*/
/*    color: #FFFFFF;*/
/*    transition: all 0.4s;*/
/*    background: #5E5AB9;*/
/*    !* global_wrappers-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.2); *!*/
/*    border-radius: 12px;*/
/*    cursor: pointer;*/
/*    border-radius: 12px;*/
/*    !* padding: 8px 20px; *!*/
/*    align-items: center;*/
/*    margin-right: 25px;*/
/*    width: 130px;*/
/*    height: 40px;*/
/*    justify-content: center;*/
/*    font-size: 20px;*/
/*}*/

/*#arrow_next_move {*/
/*    margin-left: 15px;*/
/*    fill: white;*/
/*}*/

/*.next_move:active {*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*    border-radius: 12px;*/
/*}*/

/*.blur_mode {*/
/*    filter: blur(2px) contrast(30%);*/
/*}*/

/*.MuiFilledInput-underline:after, .MuiFilledInput-underline:before, .MuiInput-underline:before {*/
/*    border-bottom: none !important;*/
/*}*/

/*.description_step {*/
/*    transition: all 0.4s;*/
/*    color: #ffffff;*/
/*    font-size: 22px;*/
/*    display: none;*/
/*    position: fixed;*/
/*    z-index: 10000000;*/


/*}*/

/*.desctop_final_step_nm {*/
/*    right: 35%;*/
/*}*/

/*.description_step_arc_btns {*/
/*    top: 62%;*/
/*    left: 36%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.description_step_one {*/
/*    !* top: 66%; *!*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.description_step_two {*/
/*    !* top: 59%;*/
/*    left:75%; *!*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.description_step_three {*/
/*    top: 55%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.description_step_four {*/
/*    top: 65%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.description_step_five {*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.description_step_six {*/
/*    top: 60%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.bord_top_start {*/

/*}*/

/*.bord_top_end {*/
/*    transform: rotate(90deg)*/

/*}*/

/*.bord_bottom_start {*/
/*    transform: rotate(270deg)*/
/*}*/

/*.bord_bottom_end {*/
/*    transform: rotate(180deg)*/
/*}*/

/*.MuiFilledInput-inputMarginDense {*/
/*    padding-top: 23px !important;*/
/*    padding-bottom: 23px !important;*/
/*}*/

/*.hint_indicator {*/
/*    position: absolute;*/
/*    !* display: none; *!*/
/*    display: none;*/
/*    top: 35px;*/
/*    right: 39px;*/
/*    width: 200px;*/
/*    height: 75px;*/
/*    !* border: 2px solid; *!*/
/*    background-color: #EFF3FB;*/
/*    box-shadow: -5px 10px 14px rgb(0 0 0 / 6%);*/
/*    z-index: 100;*/
/*    border-radius: 20px;*/
/*    font-size: 18px;*/
/*    color: #2F2A61;*/
/*    padding: 12px;*/
/*}*/

/*.hint_indicator::after, .hint_indicator::before {*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    position: absolute;*/
/*    content: '';*/
/*    top: 89px;*/
/*    left: 25px;*/
/*    background: #EFF3FB;*/
/*    z-index: -1;*/
/*    transform: rotate(*/
/*            45deg*/
/*    );*/
/*    box-shadow: 5px 10px 14px rgb(0 0 0 / 6%);*/
/*}*/

/*.hint_indicator::before {*/
/*    z-index: 1015;*/
/*    box-shadow: none;*/
/*}*/


/*!* .overlay {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    position: absolute;*/
/*    z-index: 10000;*/
/*} *!*/


/*div.circle_col_block {*/
/*    width: 450px;*/
/*}*/


/*.circle_col_right {*/
/*    text-align: right;*/
/*}*/

/*.circle_col_left {*/
/*    text-align: left;*/
/*}*/

/*.close_info_frame_btn {*/
/*    width: 100%;*/
/*    box-shadow: -0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%);*/
/*    padding: 10px 20px 10px;*/
/*    color: #5E5AB9;*/
/*    font-family: gilroy;*/
/*    transition: all 0.4s;*/
/*    text-align: center;*/
/*    font-size: 20px;*/
/*    font-weight: 600;*/
/*    border-radius: 12px;*/
/*    margin-bottom: 25px;*/
/*}*/



/*.close_info_frame_btn:active {*/
/*    box-shadow: none;*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*}*/



/*.mobBtnInfoFrameClose {*/
/*    display: none;*/
/*    margin-bottom: 45px;*/
/*    width: 260px;*/
/*}*/

/*.frame_info {*/
/*    width: 100%;*/
/*    display: none;*/
/*    justify-content: space-between;*/
/*    margin-top: 55px;*/
/*}*/

/*.wrap_frame_info {*/
/*    display: none;*/
/*    !*justify-content: center;*!*/
/*}*/

/*.frame_info_column {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/

/*}*/

/*.col_left {*/
/*    align-items: flex-start;*/
/*}*/

/*.col_right {*/
/*    align-items: flex-end;*/
/*}*/


/*.lang_list_item {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    box-shadow: -0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%);*/
/*    padding: 10px 0px 10px 0px;*/
/*    align-items: center;*/
/*    margin-bottom: 32px;*/
/*    transition: all 0.4s;*/
/*    width: 100%;*/
/*    border-radius: 12px;*/

/*    !*width: 100%;*!*/
/*}*/

/*.frame_info_block {*/
/*    background: linear-gradient(*/
/*            0deg, #E3E9F7, #E3E9F7);*/
/*    !* global_wrappers-shadow: -9px -9px 24px rgb(255 255 255 / 57%), 20px 22px 40px rgb(0 0 0 / 8%); *!*/
/*    padding: 15px;*/
/*    margin-bottom: 20px;*/
/*    border-radius: 20px;*/
/*    color: #2F2A61;*/

/*    font-family: 'gilroy';*/
/*    font-weight: 600;*/
/*    width: 350px;*/
/*    !*height: 200px;*!*/
/*    font-size: 20px;*/
/*}*/

/*.wrap_logo {*/
/*    width: 314px;*/
/*    height: 314px;*/
/*    z-index: 900;*/

/*    !*todo#### предзагрузка ####*/
/*       display: grid; по умолчанию*/
/*       display: none; для отключения прелоадера*/
/*       *!*/
/*    display: grid;*/

/*    !* transition: all 4s; *!*/

/*    align-content: center;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    justify-items: center;*/

/*    border-radius: 50%;*/
/*    background: #EFF3FB;*/
/*    box-shadow: -10px -8px 11px rgba(255, 255, 255, 0.25), 10px 14px 24px rgba(0, 0, 0, 0.12);*/
/*}*/


/*.frame_info_column_with_logo_mob {*/
/*    display: none;*/
/*    justify-content: space-between;*/
/*    width: 100%;*/
/*}*/

/*#logo_in_frame_svg {*/

/*}*/

/*.frame_info_column_with_logo {*/
/*    padding: 0px 50px;*/
/*}*/

/*.lang_list_item:active {*/
/*    box-shadow: none;*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*}*/

/*.wrap_save_btn {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    !*padding: '12px 5px'*!*/
/*}*/


/*.lang_list {*/
/*    list-style: none;*/
/*    padding: 0;*/
/*    width: 100%;*/
/*    min-width: 250px;*/
/*    !*padding: 0px 10px;*!*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.wrap_lang_list {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    !*margin: 0px 15px;*!*/
/*}*/

/*.title_select_lang {*/

/*    color: #5E5AB9;*/
/*    font-family: Gilroy semibold;*/
/*}*/

/*.save_Lang_Btn {*/
/*    width: 100%;*/
/*    height: 55px;*/
/*    !* background: linear-gradient( */
/*-244deg*/
/* ,#d0d8e9, #ffffff 80%); *!*/

/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    background: #5E5AB9;*/
/*    box-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.2);*/
/*    border-radius: 12px;*/
/*    color: white;*/
/*    font-size: 24px;*/
/*    font-weight: 600;*/
/*    transition: all 0.4s;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.save_Lang_Btn:active {*/
/*    background: linear-gradient(0deg, #5E5AB9, #5E5AB9);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.1), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*}*/

/*!**/
/*.selected_lang {*/
/*   border: 2px solid greenyellow;*/
/*} *!*/

/*#flag_svg_ru {*/
/*    width: 25px;*/
/*    height: 25px;*/
/*}*/

/*#flag_svg_pl {*/
/*    width: 25px;*/
/*    height: 25px;*/
/*}*/

/*.language_frame {*/
/*    padding: 0px 15px;*/
/*    !* display: none; *!*/
/*    text-align: center;*/
/*    margin-top: 60px;*/
/*}*/

/*.lang_svg {*/

/*    display: flex;*/
/*    align-items: center;*/

/*}*/

/*.lang_name {*/
/*    color: #5E5AB9;*/
/*    !* font-family: Gilroy semibold; *!*/
/*}*/


/*.selected_lang {*/
/*    font-family: Gilroy semibold;*/
/*    box-shadow: none;*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*}*/


/*.wrap_shinobi {*/
/*    width: 78%;*/
/*    position: relative;*/
/*}*/

/*.shinobi_shadow {*/
/*    display: block;*/
/*    position: absolute;*/
/*    height: 10px;*/
/*    width: 100%;*/
/*    box-shadow: 5px 10px 14px rgba(0, 0, 0, 0.08);*/
/*    top: -10px;*/
/*}*/

/*.polukTop {*/
/*    width: 100%;*/
/*    height: 50%;*/
/*    background: #dae1ee;*/
/*    border-top-left-radius: 250px;*/
/*    border-top-right-radius: 250px;*/
/*    border-bottom: 0;*/
/*}*/

/*.polukBot {*/
/*    width: 100%;*/
/*    height: 50%;*/
/*    background: linear-gradient(-270deg, #FF5340 -4.33%, #F9A91A 88.5%);*/
/*    !* global_wrappers-shadow: 0px 2px 11px rgba(0, 0, 0, 0.106042), inset 12px 7px 17px rgba(0, 0, 0, 0.200175); *!*/
/*    border-bottom-left-radius: 250px;*/
/*    border-bottom-right-radius: 250px;*/
/*    border-bottom: 0;*/
/*    position: relative;*/
/*}*/

/*.oval {*/
/*    min-width: 100px;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    background: linear-gradient(#d0d8e9, #ffffff 80%);*/
/*    border-radius: 50%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    box-shadow: -10px -8px 11px rgb(255 255 255 / 25%), 10px 14px 24px rgb(0 0 0 / 12%);*/
/*}*/

/*.oval_pro {*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    background: linear-gradient(#d0d8e9, #ffffff 80%);*/
/*    border-radius: 50%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    box-shadow: -10px -8px 11px rgb(255 255 255 / 25%), 10px 14px 24px rgb(0 0 0 / 12%);*/
/*}*/

/*.in_oval {*/
/*    width: 90px;*/
/*    height: 90px;*/
/*    background: #eff3fb;*/
/*    border-radius: 50%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.in_oval_two {*/
/*    width: 80px;*/
/*    height: 80px;*/
/*    background: #dde2f2;*/
/*    border-radius: 50%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    position: relative;*/
/*}*/


/*.final_oval {*/
/*    width: 72px;*/
/*    height: 72px;*/
/*    background: #eff3fb;*/
/*    border-radius: 50%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    z-index: 100;*/
/*}*/

/*.final_oval ui {*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 23px;*/
/*    color: #5E5AB9;*/
/*    margin-top: 10px;*/
/*    text-align: center;*/
/*    font-family: Gilroy Semibold;*/
/*    letter-spacing: -1.6px;*/
/*}*/

/*.final_oval a {*/
/*    font-style: normal;*/
/*    font-weight: 250;*/
/*    font-size: 16px;*/
/*    color: #2F2A61;*/
/*    text-align: center;*/
/*    opacity: 0.7;*/
/*}*/

/*.wrap_oval {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*}*/

/*.wrap_oval_pro {*/
/*    display: flex;*/
/*    !*justify-content: flex-end;*!*/
/*    align-items: center;*/
/*    width: 100%;*/
/*}*/

/*.title_oval {*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 20px;*/
/*    color: #2F2A61;*/
/*    !*margin-right: 10px;*!*/
/*    !*margin-left: 16px;*!*/
/*    margin-bottom: 15px;*/
/*    !*max-width: 120px;*!*/
/*    line-height: 16px;*/
/*}*/

/*.title_oval_pro {*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 20px;*/
/*    color: #2F2A61;*/
/*    margin-right: 10px;*/
/*    margin-left: 16px;*/
/*    margin-bottom: 15px;*/
/*    max-width: 120px;*/
/*    line-height: 16px;*/
/*}*/

/*.btns_for_rad_block {*/
/*    display: flex;*/

/*    justify-content: space-around;*/
/*    !* margin-right: 19px; *!*/
/*    height: 50%;*/
/*    width: 35%;*/
/*}*/

/*.wrap_ovals_and_btns {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*}*/

/*.unk_btn {*/
/*    width: 95%;*/
/*    height: 95%;*/
/*    background: #E3E9F7;*/

/*    border-radius: 50%;*/
/*    border: none;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    !* transition: all 0.4s; *!*/
/*}*/

/*.unk_btn:active {*/

/*    background: linear-gradient(*/
/*            0deg, #DFE6F6, #DFE6F6);*/
/*}*/

.text_in_rad_btn {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #5E5AB9;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.title_ov_block {*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 24px;*/
/*    color: #2F2A61;*/
/*    !* width: 100%; *!*/

/*    margin-bottom: 45px;*/
/*    margin-left: 21px;*/
/*}*/

/*.wrap_ovals {*/
/*    width: 100%;*/
/*    !* margin-left: 21px; *!*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/

/*.btn_bord {*/
/*    width: 48px;*/
/*    height: 48px;*/
/*    background: linear-gradient(*/
/*            -244deg, #d0d8e9, #ffffff 80%);*/
/*    border-radius: 50%;*/
/*    transition: all 0.4s;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    box-shadow: -5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12);*/
/*}*/

/*.btn_bord:active {*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*    box-shadow: none;*/
/*    background: transparent;*/
/*}*/

/*.canvRad {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    z-index: 1;*/
/*    transform: translate(-50%, -50%);*/
/*    !* transform: rotate(45deg); *!*/
/*}*/

/*.a {*/
/*    width: 500px;*/
/*    height: 500px;*/
/*    border-radius: 50%;*/
/*    background: #e2e8f5;*/
/*    position: relative;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    background: #E3E9F7;*/
/*    box-shadow: -9px -8px 21px rgba(255, 255, 255, 0.75), 8px 6px 37px rgba(0, 0, 0, 0.11);*/
/*    margin-top: 50px;*/
/*}*/

/*.b {*/
/*    width: 90%;*/
/*    height: 90%;*/
/*    !* background: linear-gradient( #d0dcf9 50%, #FF5340 4%, #F9A91A 88%); *!*/
/*    !*   margin-left: 300px; *!*/
/*    !* border-radius: 50%; *!*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    transition: all 1.5s;*/
/*}*/

/*.b::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    width: 67.5px;*/
/*    height: 32px;*/
/*    z-index: 1;*/
/*    top: 195px;*/
/*    border-top-left-radius: 34px;*/
/*    border-top-right-radius: 34px;*/
/*    left: 0px;*/
/*    !*background: #fe5d3b; херня с радиалкой. пожелание клиента так сказать. не трога если что вернуть можно будет раскоментированием*!*/
/*}*/

/*.toch_wrap {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    z-index: 380;*/
/*}*/

/*.t1 {*/
/*    position: absolute;*/
/*    right: 40px;*/
/*    top: -54px;*/
/*    z-index: 70053;*/
/*    width: 100px;*/
/*    transform: rotate(*/
/*            233deg*/
/*    );*/
/*    height: 100px;*/
/*}*/

/*.tear {*/
/*    position: absolute;*/
/*    display: none;*/
/*    left: 0;*/
/*    top: 0;*/
/*    z-index: 70053;*/
/*    width: 230px;*/
/*    height: 101px;*/
/*}*/

/*.c {*/
/*    width: 70%;*/
/*    height: 70%;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    background: #e2e8f5;*/
/*    border-radius: 50%;*/
/*    position: absolute;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.tochka {*/
/*    width: 1px;*/
/*    height: 1px;*/
/*    position: relative;*/
/*    transition: all 1.5s;*/
/*}*/

/*.c::after {*/
/*    !* content: ''; *!*/
/*    position: absolute;*/
/*    left: 0px;*/
/*    top: 45%;*/
/*    width: 28px;*/
/*    height: 28px;*/
/*    background-image: url('images/tear.svg');*/
/*    background-size: 28px 28px;*/
/*    !* border-top: 10px solid transparent;*/
/*    border-right: 45px solid  #F9A91A ;*/
/*    border-bottom: 10px solid transparent; *!*/
/*    z-index: 1000;*/

/*    !* background: ; *!*/
/*    !* global_wrappers-shadow: 0px 5px 8px rgba(0, 0, 0, 0.182446); *!*/
/*}*/

/*div#body {*/
/*    display: grid;*/
/*    width: 100%;*/
/*    height: 100%;*/

/*    align-items: center;*/
/*    justify-items: center;*/
/*    !* margin-top: 10%; *!*/
/*}*/


/*.oval_back {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    z-index: 1;*/
/*    transform: translate(-50%, -50%);*/
/*    width: 100%;*/
/*}*/

/*div#preloader {*/
/*    width: 314px;*/
/*    height: 314px;*/
/*    z-index: 900;*/

/*    !*todo#### предзагрузка ####*/
/*       display: grid; по умолчанию*/
/*       display: none; для отключения прелоадера*/
/*       *!*/
/*    display: grid;*/

/*    transition: all 4s;*/

/*    align-content: center;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    justify-items: center;*/

/*    border-radius: 50%;*/
/*    background: #EFF3FB;*/
/*    box-shadow: -10px -8px 11px rgba(255, 255, 255, 0.25), 10px 14px 24px rgba(0, 0, 0, 0.12);*/
/*}*/

/*.dot_preloader {*/
/*    fill: #EFF3FB;*/
/*    opacity: 0;*/
/*    transition: opacity 2s;*/
/*}*/

/*.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*}*/

/*.leaflet-pane {*/
/*    z-index: 400;*/
/*}*/

/*.leaflet-zoom-animated {*/
/*    -webkit-transform-origin: 0 0;*/
/*    -ms-transform-origin: 0 0;*/
/*    transform-origin: 0 0;*/
/*}*/

/*.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {*/
/*    pointer-events: none;*/
/*}*/

/*.leaflet-container .leaflet-overlay-pane svg, .leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {*/
/*    max-width: none !important;*/
/*    max-height: none !important;*/
/*}*/

/*.leaflet-fade-anim .leaflet-tile {*/
/*    will-change: opacity;*/
/*}*/

/*div#content_page {*/
/*    !*todo#### предзагрузка ####*/
/*       display: none; по умолчанию*/
/*       display: grid; для отключения прелоадера*/
/*       *!*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    !* display: grid; *!*/
/*    width: 96%;*/
/*    height: 99%;*/
/*    transition: all 4s;*/
/*    opacity: 1;*/

/*    !* grid-template-columns: 1fr;*/
/*    grid-template-rows: max-content max-content; *!*/
/*    grid-gap: 2%;*/
/*}*/

/*div#content_rec {*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);*/
/*    box-shadow: -0.5vh -0.5vh 1.4vh rgba(255, 255, 255, 0.57), 1vh 1vh 2vh rgba(0, 0, 0, 0.083);*/
/*    border-radius: 24px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    width: 100%;*/
/*    cursor: default;*/
/*    position: relative;*/
/*    box-sizing: border-box;*/
/*}*/

/*div#content_rec.table_pro {*/
/*    margin-top: 15px;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*    display: block;*/
/*    margin-bottom: 15px;*/
/*    min-height: 200px;*/
/*    position: relative;*/
/*    padding: 15px 15px 15px;*/
/*}*/

/*div#content_rec.table_home {*/
/*    position: relative;*/
/*    margin-top: 15px;*/
/*    margin-left: 25px;*/
/*    margin-right: 25px;*/
/*    padding: 0;*/
/*    box-shadow: none;*/
/*    font-family: gilroy;*/
/*    display: block;*/
/*}*/



/*div#content_rec div#c_f_header {*/
/*    position: absolute;*/
/*    display: grid;*/
/*    min-width: 122px;*/
/*    width: auto;*/
/*    height: 29px;*/
/*    background-color: #5E5AB9;*/
/*    top: 0;*/
/*    left: 0;*/
/*    color: #ffffff;*/
/*    font-size: 24px;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    padding: 13px 16px;*/
/*    text-overflow: ellipsis;*/
/*    border-radius: 2.4vh 0 2.4vh 0;*/
/*    z-index: 1001;*/
/*    align-content: center;*/
/*    font-weight: 600;*/
/*    justify-content: center;*/
/*}*/

/*div#content_rec div#c_f_area {*/
/*    grid-area: c_f_a;*/
/*    display: grid;*/
/*    justify-content: center;*/
/*    align-content: center;*/
/*    min-height: 400px;*/

/*    !* margin-bottom: 10vw; *!*/
/*}*/

/*div#scale {*/
/*    position: absolute;*/
/*    z-index: 370;*/
/*    top: -39px;*/
/*    transition: all 0.4s;*/
/*    transform-origin: 50% 100%;*/
/*    transform: rotate(-5deg);*/
/*    left: -40px;*/
/*    width: 400px;*/
/*    height: 200px;*/
/*}*/

/*div#scale_mask {*/
/*    display: block;*/
/*    position: absolute;*/
/*    width: 200px;*/
/*    height: 200px;*/
/*    left: 200px;*/
/*    top: 20px;*/
/*    transition: all 0.4s;*/
/*    background-color: #D0DCF9;*/
/*    z-index: 275;*/
/*}*/

/*div#pointer {*/
/*    display: block;*/
/*    position: absolute;*/
/*    transition: all 0.4s;*/
/*    width: 200px;*/
/*    height: 200px;*/
/*    z-index: 380;*/
/*    left: 0px;*/
/*    !* top: 190px; *!*/
/*    transform: rotate(2deg);*/
/*    transform-origin: 64% 50%;*/
/*}*/

/*.pub_block {*/
/*    width: 100%;*/
/*    height: 115px;*/
/*    z-index: 370;*/
/*    background: linear-gradient(*/
/*            0deg, #E3E9F7, #E3E9F7);*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*div#oval_rect {*/
/*    position: absolute;*/
/*    display: grid;*/
/*    width: 200px;*/
/*    height: 200px;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    z-index: 400;*/


/*    border-radius: 100%;*/
/*    !* background-color: #E3E9F7; *!*/
/*    background-image: url(images/oval.svg);*/
/*    background-repeat: no-repeat;*/
/*    background-position: 38% 32%;*/
/*    background-size: 160%;*/
/*    box-shadow: -10px -8px 11px rgba(255, 255, 255, 0.25), 10px 14px 24px rgba(0, 0, 0, 0.12);*/

/*    justify-content: center;*/
/*    align-content: center;*/
/*}*/

/*div#oval_rect ui {*/
/*    font-size: 43px;*/
/*    color: #5E5AB9;*/
/*    font-weight: bold;*/
/*    text-align: center;*/
/*    font-family: Gilroy Semibold;*/
/*}*/

/*div#oval_rect a {*/
/*    font-size: 25px;*/
/*    color: #2F2A61;*/
/*    text-align: center;*/
/*}*/

/*div#mask_rect {*/
/*    display: grid;*/
/*    !* position: absolute; *!*/
/*    !* top: 6%; *!*/
/*    !* bottom: 50%; *!*/
/*    !* left: 3%; *!*/
/*    !* right: 22%; *!*/
/*    justify-items: center;*/
/*    align-items: center;*/
/*}*/

/*div#polubox {*/
/*    display: grid;*/
/*    width: 700px;*/
/*    height: 415px;*/
/*    !* margin-bottom: 100px; *!*/
/*    z-index: 350;*/
/*    overflow: hidden;*/
/*    position: relative;*/
/*    justify-items: center;*/
/*    align-items: center;*/
/*    position: relative;*/
/*}*/

/*div.polubox_number {*/
/*    display: block;*/
/*    position: absolute;*/
/*    font-size: 28px;*/
/*    color: #2F2A61;*/
/*}*/

/*div#txt_1 {*/
/*    left: 22px;*/
/*    top: 272px;*/
/*}*/

/*div#txt_2 {*/
/*    top: 10px;*/
/*}*/

/*div#txt_3 {*/
/*    right: 22px;*/
/*    top: 272px;*/
/*}*/

/*div#polubox div#x1 {*/
/*    display: block;*/
/*    position: absolute;*/
/*    border-radius: 100%;*/
/*    z-index: 360;*/
/*    width: 400px;*/
/*    height: 400px;*/
/*    background: #E3E9F7;*/
/*    box-shadow: -9px -8px 21px rgba(255, 255, 255, 0.75), 8px 6px 37px rgba(0, 0, 0, 0.11);*/
/*    top: 90px;*/
/*}*/

/*div#polubox div#x2 {*/
/*    display: block;*/
/*    position: absolute;*/
/*    border-radius: 100%;*/
/*    z-index: 365;*/
/*    width: 340px;*/
/*    height: 340px;*/
/*    background: linear-gradient(270deg, #FF5340 -4.33%, #F9A91A 88.5%);*/
/*    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.106042), inset 12px 7px 17px rgba(0, 0, 0, 0.200175);*/
/*    top: 120px;*/
/*    overflow: hidden;*/
/*}*/

/*div#polubox div#x3 {*/
/*    display: flex;*/
/*    position: absolute;*/
/*    border-radius: 100%;*/
/*    z-index: 380;*/
/*    width: 260px;*/
/*    height: 260px;*/
/*    background: #E3E9F7;*/
/*    top: 162px;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*div.btn {*/
/*    display: grid;*/
/*    align-items: center;*/
/*    justify-items: center;*/
/*    width: 5vw;*/
/*    height: 5vw;*/
/*    border-radius: 1.2vw;*/
/*    box-shadow: -0.9vw -0.9vw 1.6vw rgba(255, 255, 255, 0.6), 2vw 2.2vw 4vw rgba(63, 60, 255, 0.12);*/
/*    transition: all 0.4s;*/
/*    cursor: pointer;*/
/*}*/


/*.pub_no_data_chart {*/
/*    position: absolute;*/
/*    font-size: 30px;*/
/*    font-weight: 600;*/
/*    top: 40%;*/
/*    left: 50%;*/
/*    color: #abb6c4;*/
/*    transform: translate(-50%, 50%);*/
/*}*/

/*div.btn:active {*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*    box-shadow: 0 0 0 rgba(255, 255, 255, 0.6), 0 0 0 rgba(63, 60, 255, 0.12);*/
/*}*/

/*div.btn.regen:active {*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*    box-shadow: -0.9vw -0.9vw 1.6vw rgba(255, 255, 255, 0.6), 2vw 2.2vw 4vw rgba(63, 60, 255, 0.12);*/
/*}*/

/*div.btn.regen {*/
/*    background: #5E5AB9;*/
/*    width: 70px;*/
/*    height: 70px;*/
/*    !*opacity: 0.2;*!*/
/*}*/

/*div.btn.info {*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);*/
/*    width: 70px;*/
/*    height: 70px;*/
/*}*/

/*div#content_rec div#c_f_ahtung {*/
/*    grid-area: c_f_ah;*/
/*    display: grid;*/
/*    justify-content: center;*/
/*    align-content: center;*/

/*    transition: all 0.2s;*/
/*    cursor: pointer;*/
/*}*/

/*div#content_rec div#c_f_ahtung:active {*/
/*    transform: translate(-0.2vw, 0.2vw);*/
/*}*/

/*div#content_rec div#c_f_click {*/
/*    grid-area: c_f_c;*/
/*    display: grid;*/
/*    justify-content: center;*/
/*    align-content: center;*/
/*    grid-template-columns: max-content;*/
/*    grid-template-rows: max-content max-content;*/
/*    grid-gap: 3vw;*/
/*    justify-items: center;*/
/*    align-items: center;*/
/*}*/

.prots_state {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 22px !important;

    /* identical to global_wrappers height */


    color: #5E5AB9;
}

/*.prots_max {*/
/*    font-family: Gilroy;*/
/*    font-style: normal;*/

/*    font-size: 18px !important;*/
/*    opacity: 0.7;*/
/*    !* identical to global_wrappers height *!*/


/*    color: #5E5AB9 !important;*/
/*}*/


/*.b_r_4 {*/
/*    border-radius: 4px;*/
/*}*/


/*.flex_in {*/
/*    display: flex;*/
/*    align-items: baseline;*/
/*}*/

/*.kosaya {*/
/*    font-family: Gilroy;*/
/*    font-style: normal;*/

/*    font-size: 18px !important;*/
/*    opacity: 0.7;*/
/*    !* identical to global_wrappers height *!*/


/*    color: #5E5AB9 !important;*/
/*}*/


/*div#content_bak {*/
/*    !* background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    global_wrappers-shadow: inset -0.4vh -0.5vh 1.7vh rgba(255, 255, 255, 0.7), inset 0.6vh 0.8vh 1.9vh rgba(0, 0, 0, 0.08); *!*/
/*    border-radius: 2.4vh;*/
/*    height: 95%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    grid-template-columns: 1fr;*/
/*    !* gap: 8vh; *!*/
/*    padding: 20px 16px;*/
/*}*/

/*div#info_status_now {*/
/*    font-size: 16px;*/
/*    color: #2F2A61;*/
/*    display: block;*/
/*}*/

.mt0 {
    margin-top: 0;
}

.mt25 {
    margin-top: 25px;
}

.pos_rel {
    position: relative;
}

.mb25 {
    margin-bottom: 25px;
}

.chart_home_wrap {
    width: 100%;
    position: relative;
    margin-top: 25px;

    background: linear-gradient(
            0deg, #E3E9F7, #E3E9F7);
}

/*!*div#info_status_now a:first-child{font-weight: 600; font-size: 20px;}*!*/


/*div#statusbar {*/
/*    width: 100%;*/

/*    display: grid;*/
/*    z-index: 800;*/

/*    grid-template-columns: max-content 1fr 15%;*/
/*    grid-template-rows: 1fr;*/
/*    grid-gap: 0.8vh;*/

/*    justify-items: center;*/
/*    align-items: center;*/
/*    !*todo#### предзагрузка ####*/
/*       opacity: 0; по умолчанию*/
/*       opacity: 1; для отключения прелоадера*/
/*       *!*/
/*    opacity: 0;*/
/*    transition: all 0.4s;*/
/*}*/

/*div#time_work {*/
/*    padding-left: 0.8vh;*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    left: 10px;*/
/*    opacity: 0.1;*/
/*    transition: all 1s;*/
/*}*/

/*.menu_btns {*/
/*    display: flex;*/
/*    position: absolute;*/
/*    z-index: 1000;*/
/*    flex-direction: column;*/
/*    right: 10px;*/
/*    height: 100%;*/
/*    justify-content: space-around;*/
/*}*/

/*div#stat_bar {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*div#time_work:hover {*/
/*    opacity: 0.8;*/
/*}*/

/*div#icobar {*/
/*    position: absolute;*/
/*    right: 0;*/
/*    top: 0;*/
/*    width: 150px;*/
/*    padding-bottom: 5px;*/
/*    height: 50px;*/
/*    z-index: 999;*/
/*    background-color: #EFF3FB;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    display: flex;*/
/*    grid-template-columns: 1fr;*/
/*    grid-template-rows: 1fr 1fr;*/
/*    justify-items: center;*/
/*    padding-right: 10px;*/
/*    padding-top: 5px;*/
/*    border-radius: 0px 0px 0px 20px;*/
/*    box-shadow: -5px 10px 14px rgb(0 0 0 / 6%);*/
/*}*/

/*div#icobar::after, div#icobar::before {*/
/*    !* width: 0;*/
/*    height: 0;*/
/*    position: absolute;*/
/*    content: '';*/
/*    top: 0px;*/
/*    left: -20px;*/
/*    z-index: 10000;*/
/*    border-top: 45px solid #EFF3FB;*/
/*    border-left: 20px solid transparent; *!*/
/*    width: 80px;*/
/*    height: 35px;*/
/*    position: absolute;*/
/*    content: '';*/
/*    top: -14px;*/
/*    left: -44px;*/
/*    background: #EFF3FB;*/
/*    z-index: -1;*/
/*    transform: rotate(*/
/*            60deg*/
/*    );*/
/*    box-shadow: 5px 10px 14px rgb(0 0 0 / 6%);*/
/*}*/

/*div#icobar::before {*/
/*    z-index: 1015;*/
/*    box-shadow: none;*/

/*}*/

/*.statuses_batt {*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*    margin-right: 5px;*/
/*}*/


/*div#icobar div {*/
/*    display: flex;*/
/*    height: 25px;*/
/*    width: 100%*/
/*}*/

/*div#icobar div svg {*/
/*    height: inherit;*/
/*}*/

/*div#icobar div ui {*/
/*    !* display: grid; *!*/
/*    font-size: 15px;*/

/*    align-content: end;*/
/*}*/

/*div.frame_win {*/
/*    display: grid;*/
/*    position: fixed;*/
/*    left: 0;*/
/*    right: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    background-color: #E3E9F7;*/
/*    overflow-x: hidden;*/
/*    overflow-y: auto;*/
/*    padding: 10%;*/
/*    grid-template-rows: max-content 1fr max-content;*/
/*}*/

/*div.frame_win div.frame_header {*/
/*    display: grid;*/
/*    height: 10vh;*/
/*    justify-items: center;*/
/*    justify-self: center;*/
/*    align-self: center;*/
/*    align-items: center;*/
/*    font-size: 4vh;*/
/*    font-weight: bold;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*    width: 100%;*/
/*    cursor: default;*/
/*}*/

/*div.frame_win div.frame_body {*/
/*    display: grid;*/
/*    text-indent: 4%;*/
/*    text-align: justify;*/
/*    text-transform: none;*/
/*    text-decoration: none;*/
/*    font-size: 2.5vh;*/
/*    margin-bottom: 4vh;*/
/*}*/

/*div.frame_win div.frame_buttons {*/
/*    display: grid;*/
/*    height: 15vh;*/
/*    justify-items: center;*/
/*    align-items: baseline;*/
/*    font-weight: bold;*/

/*    grid-column-gap: 4%;*/
/*}*/

/*div.buttons_ok, div.buttons_cancel {*/
/*    display: grid;*/
/*    box-shadow: -0.9vw -0.9vw 1.6vw rgba(255, 255, 255, 0.6), 2vw 2.2vw 4vw rgba(63, 60, 255, 0.12);*/
/*    border-radius: 1.2vw;*/
/*    justify-items: center;*/
/*    align-items: center;*/
/*    transition: all 0.4s;*/
/*    width: 100%;*/
/*    height: 6vh;*/

/*    cursor: pointer;*/
/*}*/

/*div.buttons_ok {*/
/*    background: #5E5AB9;*/
/*    color: #FFFFFF;*/
/*}*/

/*div.buttons_cancel {*/
/*    color: #2F2A61;*/
/*    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);*/
/*}*/

/*div.buttons_ok:active, div.buttons_cancel:active {*/
/*    transform: translate(-0.4vw, 0.4vw);*/
/*    box-shadow: 0 0 0 rgba(255, 255, 255, 0.6), 0 0 0 rgba(63, 60, 255, 0.12);*/
/*}*/

/*!*############# анимация прелоадера #############*!*/
/*@keyframes ckw {*/
/*    0% {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/

/*div#shadow_bar {*/
/*    display: block;*/
/*    position: absolute;*/
/*    z-index: 998;*/
/*    right: 0;*/
/*    top: 0;*/
/*    height: 5%;*/
/*    width: 11%;*/
/*    border-radius: 0 0 0 40%;*/
/*    box-shadow: -1vh 1vh 1vh rgba(0, 0, 0, 0.064);*/
/*}*/

/*.block_new_btns {*/
/*    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);*/
/*    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 2.4vh;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    !* justify-content: space-around; *!*/
/*    z-index: 600;*/
/*    cursor: default;*/
/*    position: relative;*/
/*    padding: 20px 0px;*/
/*    height: 100%;*/
/*    margin-bottom: 20px;*/
/*    margin-top: 20px;*/
/*}*/

/*div.rebootBtn {*/
/*    width: 100px;*/
/*    height: 50px;*/
/*}*/

/*div.btn2 {*/
/*    width: 100px;*/
/*    height: 50px;*/
/*}*/

/*div.btn3 {*/
/*    width: 100px;*/
/*    height: 50px;*/
/*}*/

/*@media screen and (max-width: 1400px) {*/
/*    div.circle_col_block {*/
/*        width: 400px;*/
/*    }*/

/*    .frame_info_block {*/

/*        width: 330px;*/

/*    }*/

/*    div.btn.regen {*/

/*        width: 60px;*/
/*        height: 60px;*/
/*    }*/

/*    div.btn.info {*/

/*        width: 60px;*/
/*        height: 60px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1300px) {*/
/*    div.circle_col_block {*/
/*        width: 350px;*/
/*    }*/

/*    .frame_info_block {*/

/*        width: 295px;*/

/*    }*/
/*}*/

/*@media screen and (max-width: 1200px) {*/
/*    !* .frame_info_block { */
/*        width: 300px;*/
/*        height: 225px;*/
/*    } *!*/
/*    #logo_in_frame_svg {*/
/*        width: 250px;*/
/*        height: 250px;*/
/*    }*/

/*    .wrap_logo {*/
/*        width: 230px;*/
/*        height: 230px;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 1100px) {*/
/*    div.circle_col_block {*/
/*        width: 300px;*/
/*    }*/

/*    .frame_info_block {*/
/*        width: 230px;*/
/*        !*height: 245px;*!*/

/*    }*/
/*}*/

/*@media screen and (max-width: 1050px) {*/
/*    .wrap_ovals_and_btns {*/
/*        flex-direction: column;*/
/*        justify-content: space-between;*/
/*        align-items: center;*/
/*        !*margin-bottom: 35px;*!*/
/*    }*/

/*    .btns_for_rad_block {*/
/*        width: 100%;*/
/*    }*/

/*    .wrap_oval {*/
/*        !* justify-content: center; *!*/
/*    }*/

/*    .wrap_ovals {*/
/*        !*margin-bottom: 50px;*!*/
/*        width: 100%;*/
/*        !* margin-left: 42px; *!*/
/*    }*/
/*}*/

/*@media screen and (max-width: 850px) and (min-height: 800px) {*/
/*    .description_step_four {*/
/*        top: 70%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 850px) and (max-height: 800px) {*/
/*    .description_step_four {*/
/*        top: 60%;*/

/*    }*/

/*    .description_step_six {*/
/*        top: 50%;*/

/*    }*/
/*}*/

/*@media screen and (max-width: 650px) and (min-height: 800px) {*/
/*    .description_step_four {*/
/*        top: 70%;*/
/*    }*/

/*    .description_step_five {*/
/*        top: 70%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 650px) and (min-height: 800px) {*/
/*    .description_step_four {*/
/*        top: 70%;*/
/*    }*/

/*    .description_step_five {*/
/*        top: 34%;*/
/*        left: 40%;*/

/*    }*/

/*    .description_step_six {*/
/*        top: 50%;*/

/*    }*/
/*}*/

/*@media screen and (min-width: 650px) and (max-height: 800px) {*/


/*    .description_step_five {*/
/*        top: 65%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 650px) and (max-height: 800px) {*/
/*    .description_step_four {*/
/*        top: 55%;*/

/*    }*/

/*    .description_step_five {*/
/*        top: 70%;*/
/*    }*/

/*    .description_step_six {*/
/*        top: 25%;*/
/*        left: 38%;*/
/*        transform: translate(-50%, -50%);*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1040px) and (min-height: 800px) {*/
/*    .description_step_four {*/
/*        top: 66%;*/

/*    }*/

/*    .description_step_five {*/
/*        top: 33%;*/
/*        left: 50%;*/
/*        transform: translate(-50%, -50%);*/
/*    }*/

/*    .description_step_six {*/
/*        top: 45%;*/
/*        left: 50%;*/
/*        transform: translate(-50%, -50%);*/
/*    }*/
/*}*/

/*@media screen and (max-width: 500px) and (max-height: 580px) {*/

/*    .description_step_five {*/
/*        top: 66%;*/
/*        left: 50%;*/
/*        transform: translate(-50%, -50%);*/
/*    }*/
/*}*/

/*@media screen and (max-width: 500px) and (max-height: 500px) {*/

/*    .description_step_five {*/
/*        top: 58%;*/
/*        left: 50%;*/
/*        transform: translate(-50%, -50%);*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1040px) {*/


/*    .wrap_logo {*/
/*        width: 188px;*/
/*        height: 188px;*/
/*    }*/

/*    #logo_in_frame_svg {*/
/*        width: 200px;*/
/*        height: 200px;*/
/*    }*/

/*    div.btn.regen {*/

/*        width: 50px;*/
/*        height: 50px;*/
/*    }*/

/*    div.btn.info {*/

/*        width: 50px;*/
/*        height: 50px;*/

/*    }*/

/*    .description_step_arc_btns {*/
/*        top: 45%;*/
/*        left: 50%;*/
/*        transform: translate(-50%, -50%);*/
/*    }*/

/*}*/

/*@media screen and (max-width: 950px) {*/
/*    .description_step_two {*/
/*        left: 65%;*/
/*    }*/

/*    div.circle_col_block {*/
/*        width: 250px;*/
/*    }*/

/*    .frame_info_block {*/
/*        width: 200px;*/
/*        !*height: 300px;*!*/

/*    }*/
/*}*/


/*@media screen and (max-width: 900px) {*/
/*    div#content_rec div#c_f_click {*/
/*        display: flex;*/
/*        margin-top: 195px;*/
/*        margin-right: 8px;*/
/*    }*/

/*    div.btn.info {*/

/*        margin-right: 8px;*/
/*    }*/

/*    .close_info_frame_btn {*/
/*        margin-bottom: 20px;*/
/*    }*/

/*    div#content_rec div#c_f_ahtung {*/
/*        justify-content: flex-end;*/
/*        display: grid !important;*/
/*        margin-left: 20px;*/
/*    }*/

/*    .wrap_shinobi {*/
/*        width: 72%;*/

/*    }*/

/*    .wrap_logo {*/
/*        width: 170px;*/
/*        height: 170px;*/
/*    }*/

/*    #logo_in_frame_svg {*/
/*        width: 180px;*/
/*        height: 180px;*/
/*    }*/

/*    .frame_info_block {*/
/*        width: 210px;*/
/*        !*height: 320px;*!*/
/*    }*/

/*    !* .wrap_ovals{*/
/*        width: 65%;*/
/*    } *!*/
/*    .a {*/
/*        width: 450px;*/
/*        height: 450px;*/
/*    }*/

/*    .pub_block {*/
/*        height: 140px;*/
/*    }*/

/*    .b::after {*/
/*        width: 61.4px;*/
/*        height: 30px;*/
/*        top: 175px;*/
/*    }*/

/*    .c::after {*/
/*        border-right: 30px solid #F9A91A;*/
/*    }*/

/*    div#txt_3 {*/
/*        right: 40px;*/
/*        top: 246px;*/
/*    }*/

/*    div#txt_1 {*/
/*        left: 40px;*/
/*        top: 246px;*/
/*    }*/

/*    .t1 {*/
/*        position: absolute;*/
/*        right: 28px;*/
/*        top: -54px;*/
/*        z-index: 70053;*/
/*        width: 100px;*/
/*        transform: rotate(*/
/*                233deg*/
/*        );*/
/*        height: 100px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 850px) {*/
/*    .frame_info_column_with_logo_mob {*/
/*        display: flex;*/
/*        !* margin-bottom: 40px; *!*/
/*        margin-top: 40px;*/
/*    }*/

/*    .frame_info_column_with_logo {*/
/*        display: none;*/
/*    }*/


/*    !* .circle_col_right {*/
/*        text-align: center;*/
/*    }*/

/*    .circle_col_left {*/
/*        text-align: center;*/
/*    } *!*/
/*    div.circle_col_block {*/
/*        width: 300px;*/
/*        !*height: 200px;*!*/
/*    }*/

/*    .frame_info_block {*/
/*        width: 300px;*/
/*        !*height: 200px;*!*/
/*    }*/

/*    .wrap_frame_info {*/

/*        flex-direction: column;*/
/*        align-items: center;*/
/*    }*/

/*    .mobBtnInfoFrameClose {*/
/*        display: block;*/
/*        margin-bottom: 45px;*/
/*    }*/


/*}*/

/*@media screen and (max-width: 650px) {*/
/*    !* .description_step_four {*/
/*        top: 55%;*/

/*    } *!*/
/*    .description_step_two {*/
/*        left: 45%;*/
/*    }*/

/*    div.circle_col_block {*/
/*        width: 200px;*/
/*        !*height: 250px;*!*/
/*    }*/

/*    .frame_info_block {*/
/*        width: 200px;*/
/*        !*height: 250px;*!*/
/*        font-size: 18px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 600px) {*/


/*    .wrap_shinobi {*/
/*        width: 95%;*/

/*    }*/

/*    .title_oval {*/
/*        !* word-break: break-all; *!*/
/*        !* Запрещаем перенос строк *!*/
/*        max-width: 95px;*/
/*    }*/


/*    .t1 {*/

/*        right: -5px;*/

/*    }*/

/*    .a {*/
/*        width: 350px;*/
/*        height: 350px;*/
/*    }*/

/*    .pub_block {*/
/*        height: 175px;*/
/*    }*/

/*    .b::after {*/
/*        width: 47.8px;*/
/*        height: 30px;*/
/*        top: 135px;*/
/*    }*/


/*    .c::after {*/
/*        border-right: 25px solid #F9A91A;*/
/*    }*/

/*    body {*/
/*        min-width: 250px;*/
/*    }*/

/*    div#icobar::after, div#icobar::before {*/
/*        top: -10px;*/
/*    }*/

/*    div#icobar div ui {*/
/*        font-size: 15px;*/
/*    }*/

/*    div#icobar {*/
/*        height: 55px;*/
/*    }*/

/*    div#polubox {*/

/*        width: 400px;*/
/*        height: 400px;*/

/*    }*/

/*    div#polubox div#x1 {*/

/*        width: 350px;*/
/*        height: 350px;*/

/*    }*/

/*    div#polubox div#x2 {*/

/*        width: 290px;*/
/*        height: 290px;*/

/*    }*/

/*    div#polubox div#x3 {*/

/*        width: 210px;*/
/*        height: 210px;*/

/*    }*/

/*    div#txt_1 {*/
/*        left: 1px;*/
/*        top: 230px;*/
/*        z-index: 1000;*/
/*    }*/

/*    div#txt_3 {*/
/*        right: 1px;*/
/*        top: 230px;*/
/*        z-index: 1000;*/
/*    }*/

/*    div#oval_rect {*/
/*        width: 150px;*/
/*        height: 150px;*/
/*    }*/

/*    div#oval_rect ui {*/
/*        font-size: 35px;*/
/*        font-weight: 600;*/
/*    }*/


/*    div#oval_rect a {*/
/*        font-size: 22px;*/
/*        color: #2F2A61;*/
/*        font-weight: 250;*/
/*        text-align: center;*/
/*        !* margin-bottom: 40px; *!*/
/*    }*/

/*    div#pointer {*/

/*        width: 165px;*/
/*        height: 165px;*/
/*        !* margin-top: 20px; *!*/
/*    }*/

/*    div#scale {*/
/*        top: -55px;*/
/*    }*/

/*    div#content_rec div#c_f_area {*/
/*        min-height: 0;*/
/*    }*/

/*    div.polubox_number {*/
/*        font-size: 25px;*/
/*    }*/

/*}*/


/*@media screen and (max-width: 550px) {*/
/*    .description_step {*/

/*        font-size: 20px;*/

/*    }*/

/*    !* .frame_info_block { */
/*        width: 165px;*/
/*        height: 365px;*/
/*        font-size: 18px;*/
/*    } *!*/
/*    #logo_in_frame_svg {*/
/*        width: 150px;*/
/*        height: 150px;*/
/*    }*/

/*    .wrap_logo {*/
/*        width: 145px;*/
/*        height: 145px;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 500px) {*/


/*    #logo_in_frame_svg {*/
/*        width: 150px;*/
/*        height: 150px;*/
/*    }*/

/*    .save_Lang_Btn {*/
/*        !*width: 95%;*!*/
/*        height: 35px;*/
/*        font-size: 18px;*/
/*    }*/

/*    .next_move {*/
/*        width: 95px;*/
/*        height: 35px;*/
/*        font-size: 16px;*/
/*    }*/

/*    .skip_interaktive {*/
/*        width: 65px;*/
/*        height: 35px;*/
/*        font-size: 16px;*/
/*    }*/

/*    .info {*/
/*        min-width: 40px !important;*/
/*        min-height: 40px !important;*/
/*    }*/

/*    .regen {*/
/*        min-width: 40px !important;*/
/*        min-height: 40px !important;*/

/*    }*/


/*    .wrap_ovals {*/
/*        width: 100%;*/
/*        margin-bottom: 5px;*/
/*        !* margin-left: 42px; *!*/
/*        display: flex;*/
/*        flex-direction: row;*/
/*        justify-content: space-between;*/
/*        align-items: center;*/
/*    }*/


/*    .wrap_oval {*/
/*        !* justify-content: initial; *!*/
/*    }*/

/*    .wrap_oval_two {*/
/*        margin-top: 0;*/
/*    }*/


/*    .oval {*/
/*        min-width: 60px;*/
/*        width: 60px;*/
/*        height: 60px;*/

/*    }*/

/*    .title_oval {*/
/*        font-size: 14px;*/
/*        font-weight: 500;*/
/*        !* margin-left: 20px; *!*/
/*    }*/

/*    .final_oval ui {*/
/*        font-size: 13px;*/
/*        margin-top: 6px;*/
/*    }*/


/*    .final_oval a {*/
/*        font-size: 12px;*/
/*    }*/

/*    .in_oval {*/
/*        width: 55px;*/
/*        height: 55px;*/
/*    }*/

/*    .in_oval_two {*/
/*        width: 48px;*/
/*        height: 48px;*/
/*    }*/

/*    .final_oval {*/
/*        width: 42px;*/
/*        height: 42px;*/
/*    }*/

/*    .title_ov_block {*/
/*        font-size: 20px;*/
/*    }*/

/*    .wrap_oval {*/
/*        align-items: center;*/
/*    }*/


/*    !* .btns_for_rad_block {*/

/*        justify-content: unset;*/
/*    */
/*    } *!*/
/*    .btn_bord {*/
/*        margin-top: 30px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 450px) {*/

/*    .description_step {*/

/*        font-size: 16px;*/

/*    }*/

/*    .description_step_one {*/
/*        top: 55%;*/
/*    }*/

/*    .frame_info {*/
/*        flex-direction: column;*/
/*    }*/


/*    .circle_col_right {*/
/*        text-align: center;*/
/*    }*/

/*    .circle_col_left {*/
/*        text-align: center;*/
/*    }*/

/*    div.circle_col_block {*/
/*        width: 260px;*/
/*        !*height: 170px;*!*/
/*    }*/

/*    .frame_info_block {*/
/*        width: 260px;*/
/*        !*height: 170px;*!*/
/*    }*/

/*    .frame_info_column {*/
/*        align-items: center;*/
/*    }*/

/*    #logo_in_frame_svg {*/
/*        width: 150px;*/
/*        height: 150px;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 400px) {*/
/*    .maw400_fs14 {*/
/*        font-size: 14px !important;*/
/*    }*/

/*    .title_oval {*/
/*        !* word-break: break-all; *!*/
/*        margin-right: 10px;*/
/*        max-width: 61px;*/
/*    }*/

/*    .btn_bord {*/
/*        width: 50px;*/
/*        height: 50px;*/
/*    }*/

    .text_in_rad_btn {
        font-size: 16px;
    }


/*    div.polubox_number {*/
/*        font-size: 20px;*/
/*    }*/

/*    .a {*/
/*        width: 250px;*/
/*        height: 250px;*/
/*    }*/

/*    .t1 {*/

/*        right: -30px;*/

/*    }*/

/*    .b::after {*/
/*        width: 34.4px;*/
/*        height: 18px;*/
/*        top: 98px;*/
/*    }*/

/*    .c::after {*/
/*        border-right: 45px solid #F9A91A;*/
/*        top: 38%;*/
/*        border-top: 15px solid transparent;*/
/*        border-bottom: 15px solid transparent;*/
/*    }*/

/*    div#content_rec div#c_f_header {*/
/*        font-size: 20px;*/
/*        width: 80px;*/
/*        height: 15px;*/
/*    }*/

/*    div.line_block div.line_text a {*/
/*        font-size: 14px;*/
/*    }*/

/*    !*div#info_status_now a:first-child{*!*/
/*    !*font-size: 18px;*!*/
/*    !*}*!*/
/*    div#icobar::after, div#icobar::before {*/
/*        top: -32px;*/
/*    }*/

/*    div.rebootBtn {*/
/*        width: 80px;*/
/*        height: 50px;*/
/*    }*/

/*    div.btn2 {*/
/*        width: 80px;*/
/*        height: 50px;*/
/*    }*/

/*    div.btn3 {*/
/*        width: 80px;*/
/*        height: 50px;*/
/*    }*/

/*    div#icobar {*/
/*        height: 33px;*/
/*    }*/

/*    div#stat_bar {*/
/*        flex-direction: row-reverse;*/
/*    }*/

/*    div#icobar div ui {*/
/*        !* display: grid; *!*/
/*        font-size: 12px;*/

/*    }*/

/*    div#content_rec div#c_f_click {*/
/*        display: flex;*/

/*    }*/

/*    .menu_btns {*/
/*        !* flex-direction: row;*/
/*        right: 5px;*/
/*    top: 15px; *!*/
/*    }*/

/*    div#time_work {*/
/*        top: 5px;*/
/*    }*/

/*    .statuses_batt {*/

/*        margin-top: 0px;*/
/*        margin-left: 30px;*/
/*    }*/

/*    div#icobar div {*/
/*        height: 20px;*/
/*    }*/

/*    div#content_rec div#c_f_ahtung {*/
/*        !* margin-right: 5px; *!*/
/*    }*/

/*    div#polubox {*/

/*        width: 300px;*/
/*        height: 375px;*/

/*    }*/

/*    div#polubox div#x1 {*/

/*        width: 250px;*/
/*        height: 250px;*/
/*        !* global_wrappers-shadow: none; *!*/
/*        top: 114px;*/

/*    }*/

/*    div#polubox div#x2 {*/

/*        width: 200px;*/
/*        height: 200px;*/
/*        top: 136px;*/

/*    }*/

/*    div#polubox div#x3 {*/

/*        !* width: 175px;*/
/*        height: 160px; *!*/
/*        width: 155px;*/
/*        height: 155px;*/
/*        top: 162px;*/
/*    }*/

/*    div#txt_1 {*/
/*        left: 20px;*/
/*        top: 218px;*/
/*        z-index: 1000;*/
/*    }*/

/*    div#txt_2 {*/

/*        top: 50px;*/
/*        z-index: 1000;*/
/*    }*/

/*    div#txt_3 {*/
/*        right: 20px;*/
/*        top: 218px;*/
/*        z-index: 1000;*/
/*    }*/

/*    div#oval_rect {*/
/*        width: 110px;*/
/*        height: 110px;*/
/*        z-index: 1000;*/
/*    }*/

/*    div#oval_rect ui {*/
/*        font-size: 22px;*/
/*    }*/

/*    div#icobar {*/
/*        width: 150px;*/
/*    }*/

/*    div#oval_rect a {*/
/*        font-size: 20px;*/
/*        color: #2F2A61;*/
/*        text-align: center;*/
/*        !* margin-bottom: 40px; *!*/
/*    }*/

/*    div#pointer {*/

/*        width: 125px;*/
/*        height: 125px;*/
/*        !* margin-top: 20px; *!*/
/*    }*/

/*    div#scale {*/
/*        top: -75px;*/
/*        width: 385px;*/
/*        left: -45px;*/
/*    }*/

/*    div#content_rec div#c_f_area {*/
/*        min-height: 0;*/
/*    }*/

/*    .pub_block {*/
/*        height: 163px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 340px) {*/

/*    .description_step_one {*/
/*        top: 58%;*/
/*    }*/

/*    .title_ov_block {*/
/*        margin-left: 11px;*/
/*    }*/

/*    .title_oval {*/
/*        margin-left: 11px;*/
/*    }*/
/*}*/