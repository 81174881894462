.content_wrap.wrap_table_home {
    margin-top: 15px;
    align-items: center;
    flex-direction: column;
    display: block;
    margin-bottom: 15px;
    min-height: 200px;
    position: relative;
    padding: 15px 15px 15px;

    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    box-shadow: -0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%);
    border-radius: 24px;
    width: 100%;
    cursor: default;
    box-sizing: border-box;
}

.content_wrap.wrap_table_pro {
    margin-top: 15px;
    align-items: center;
    flex-direction: column;
    display: block;
    margin-bottom: 15px;
    min-height: 200px;
    position: relative;
    padding: 15px 15px 15px;

    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    box-shadow: -0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%);
    border-radius: 24px;
    width: 100%;
    cursor: default;
    box-sizing: border-box;
}

.wrap_selects {
    margin-top: 30px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
}

.upd_table_btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}