
.wrap_content_help_modal{
    /*display: flex;*/
    /*justify-content: center;*/
    /*flex-direction: column;*/
    margin-top: 30px;
}

.struct_wrap {
    width: 100%;
    padding-right: 13px;
    overflow-y: auto;

}