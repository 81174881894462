

.full_custom_input {
    border: 1px solid #9d9fbe;
    border-radius: 10px;

    padding: 10px 5px;



}

.full_custom_input.focused {
    border: 1px solid #2f2a61;
}

.char_fc_input.is_cursor_true {
    -webkit-animation: blink1 0.9s linear infinite;
    animation: blink1 0.9s linear infinite;
}

.char_fc_input.pub_null_cursor {
    opacity: 0;
}

@-webkit-keyframes blink1 {
    0% { color: rgba(34, 34, 34, 1); }
    50% { color: rgba(34, 34, 34, 0); }
    100% { color: rgba(34, 34, 34, 1); }
}
@keyframes blink1 {
    0% { color: rgba(34, 34, 34, 1); }
    50% { color: rgba(34, 34, 34, 0); }
    100% { color: rgba(34, 34, 34, 1); }
}