

.wrap_detached_input_with_accept {
    display: flex;
    flex-direction: column;


}

.wrap_detached_access_btns_wrap {
    display: flex;

    margin-top: 12px;
}

.light_button.cancel_change_time_input_btn {
  flex: 1;
    margin-right: 5px;
}

.cancel_change_time_input_btn_text {

}

.purple_button_outer.accept_change_time_input_btn {
    flex: 1;
    margin-left: 5px;

    box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.6), 10px 11px 20px rgba(63, 60, 255, 0.2);
}

.accept_change_time_input_btn_text {

}