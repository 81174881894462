
.content_wrap {
    margin-top: 25px;
    min-height: 50px;
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    box-shadow: -0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: default;
    position: relative;
    box-sizing: border-box;
}

.content_wrap.mobile_view {
    background: #E3E9F7!important;
    box-shadow: none!important;
    border-radius: 0px!important;
}

.wrap_mobile_header_title_block {
    width: 100%;
    display: block;
    display: flex;
    justify-content: center;
    padding: 24px 0px;
    border-bottom: 1px solid #2f2a61;
}

.mobile_header_title_block {
    color: #5e5ab9;
    font-size: 24px;
    font-weight: 600;
}

.desktop_header_title_block {
    display: grid;
    -webkit-align-content: center;
    align-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: absolute;
    /*min-width: 122px;*/
    width: auto;
    height: 29px;
    background-color: #5e5ab9;
    top: 0;
    left: 0;
    color: #fff;
    font-size:24px;
    white-space: nowrap;
    overflow: hidden;
    padding: 13px 16px;
    text-overflow: ellipsis;
    border-radius: 2.4vh 0 2.4vh 0;
    z-index: 940;
    font-weight: 600;
}

/*@media screen and (min-width: 1400px) {*/
/*    .desktop_header_title_block {*/
/*        font-size: 38px;*/
/*    }*/
/*}*/

.wrap_first_canvas {
    position: relative;width: 100%; height: 100%;
}

.wrap_second_canvas {
    position: absolute;width: 100%; height: 100%;z-index: 10;
}

.wrap_third_canvas {
    position: absolute;width: 100%; height: 100%;z-index: 20;
}
.wrap_four_canvas {
    position: absolute;width: 100%; height: 100%;z-index: 30;
}

.cur_p {
    cursor: pointer;
}

.center_flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.perc_100 {
    width: 100%;
}

.flex_end_jc {
    justify-content: flex-end;
}

.font_fam_bold {
    font-family: gilroy;
    font-weight: 600;
}

.color_gray {
    color: #aab5c3;
}





.toast-buttons {
    display: flex;
}

.toast-buttons button {
    color: white;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    height: 50px;
    margin: 0 10px;
    border: none;
    outline: none;
}

.select {
    display: flex;
    width: 30%;
    margin-top: 10px;
}

.position-select {
    background-color: inherit;
    color: #fff;
    width: 100%;
    height: 30px;
    font-size: 16px;
}

.success {
    background-color: #5cb85c;
}

.danger {
    background-color: #d9534f;
}

.info {
    background-color: #5bc0de;
}

.warning {
    background-color: #f0ad4e;
}


.checkbox_container {
    background: transparent;
    position: relative;
    /*box-shadow: inset -3px -3px 7px #FFFFFF, inset 3px 3px 0px #3A5165;*/
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
}

.wrap_check_img {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.bg_conteiner_checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0px;
    right:0px;
    bottom:0px;
    left: 0px;
    z-index: 0;
}


.span_ellipsis {

}

.ta_center {
    text-align: center;
}

.visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

.hided {
    display: none;
}


.wrap_icon {
    right: 15px;
    top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inset_bg_shadow {
    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);
    border-radius: 12px;
    box-shadow:
            inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08) !important;
}

.fl_d_c {
    flex-direction: column;
}

.pos_rel {
    position: relative;
}

.pos_abs {
    position: absolute;
}


.mg_l_10 {
    margin-left: 10px;
}

.mg_l_5 {
    margin-left: 5px;
}

.fw_bold {
    font-weight: bold;
}

.mt_10 {
    margin-top: 10px;
}

.mt_20 {
    margin-top: 20px;
}

.mt_30 {
    margin-top: 30px;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

.fl_ {
    display: flex;
}

.fl_js_c_ {
    justify-content: center;
}

.fl_ai_c_ {
    align-items: center;
}

.custom_h1 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
}
.custom_h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.custom_h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.mg_l_10 {
    margin-left: 10px;
}

.mg_r_10 {
    margin-right: 10px;
}

.mg_l_20 {
    margin-left: 20px;
}

.mg_r_20 {
    margin-right: 20px;
}

.hided {
    display: none;
}

.wrap_main_blocks {

}

.ws_nw {
    white-space: nowrap;
}

.h100 {
    height: 100%;
}

.bg_shad_init {
    background: linear-gradient(
            0deg, #E3E9F7, #E3E9F7);
    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%);
}

.bg_shad_init:hover {
    background: linear-gradient(
            0deg, #DFE6F6, #DFE6F6);
    box-shadow: inset -4px -5px 17px rgb(255 255 255 / 70%), inset 6px 8px 19px rgb(0 0 0 / 8%);
}


.mg_t_20 {
    margin-top: 20px;
}

.mg_b_20 {
    margin-bottom: 20px;
}


.flex_center_ai {
    align-items: center;
}





#info_status_now.status_home.fs20 {
    font-size: 20px;
}



.bor_rad_id {
    border-radius: 2.4vh 0 2.4vh 0;
}

.padd_id {
    padding: 13px 16px;
}

.w100 {
    width: 100%;
}


.flex_end_ai {
    align-items: flex-end;
}

.f_sz_24 {
    font-size: 24px;
}

.color_wh {
    color: white;
}

.mw_122 {
    min-width: 122px;
}

.mh_29 {
    min-height: 29px;
}


.perc_100_h {
    height: 100%;
}

.line1 {
    /*width: 100%;*/
    height: 1px;

}

.wh10 {
    width: 10px;
    height: 10px;
}


.stand_tooltip_bg {
    background: #6b6c6d;
}

.offline_id_bg {
    background: #a6b1bf !important;
}

.offline_id_cl {
    color: #e9e9e9;
}

.online_id_bg {
    background: #5e5ab9;
}

.online_id_cl {
    color: #ffffff;
}


.rotate_sq {
    transform: rotate(45deg);
}

.flex_jc_sb {
    justify-content: space-between;
}

.test_bg {
    /*width: 100%;*/
    background: #2f2a61;

}

.bg_chart_grid_line {
    background: #c1c6d2;
}

.paper_bg {
    background: white;
}

.fw_bold {
    font-weight: bold;
    color: #2f2a61;
    font-size: 16px;
}

.sheme_modal_text {
    font-size: 16px;
    color: #2f2a61;
}

.bg_chart_grid_line_bord {
    border: 1px solid #c1c6d2;
}

.column_flex {
    flex-direction: column;
}

.flex_1_ {
    flex: 1;
}

._vypuklyaya_ten_ {
    background: linear-gradient(0deg, #E3E9F7, #E3E9F7) !important;
    box-shadow: -5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%) !important;
}

._vypuklyaya_ten_hover:hover {
    background: linear-gradient(0deg, #DFE6F6, #DFE6F6) !important;
    box-shadow: inset -4px -5px 17px rgb(255 255 255 / 70%), inset 6px 8px 19px rgb(0 0 0 / 8%) !important;
    transition: all 0.4s !important;
}

.flex_between {
    display: flex;
    justify-content: space-between;
}

.marg_top_10 {
    margin-top: 10px;
}

.marg_top_20 {
    margin-top: 20px;
}