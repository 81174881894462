
.header_wrap {
    /*position: fixed;*/
    /*top: 24px;*/
    /*right: 12px;*/
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 25px;
    /*padding-right: 12px;*/
    /*z-index: 1025;*/
}

.wrap_header_item_block {
    display: flex;
    align-items: center;

    color: rgba(47,42,97,.4);
    font-family: "gilroy",serif;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    cursor: pointer;
    font-weight: 500;
    transition: font-weight .4s,color .4s;
}



.report_btn_text,.lang_btn_text {
    color: #57559a;
    margin-right: 25px;
    font-family: "gilroy",serif;
    font-size: 16px;
    font-weight: 500;


    text-align: center;
    font-style: normal;
    cursor: pointer;
    transition: font-weight .4s,color .4s;
}

.report_btn_text:hover,.lang_btn_text:hover  {
    color: #5e5ab9;
    transition: color .4s;
    font-weight: 600;
}




.report_item {
    width: 20px;
    height: 20px;
    fill: #5e5ab9;
    opacity: 1;
    cursor: pointer;
    margin-right: 12px;
}

/*.report_item:hover {*/
/*    opacity: 0.5;*/
/*}*/

.lang_item {
    width: 20px;
    height: 20px;
    fill: #5e5ab9;
    opacity: 1;
    cursor: pointer;
    margin-right: 12px;
}

.lang_item:hover {
    /*opacity: 0.5;*/
}

