
.wrap_color_list {
    display:flex;
    flex: 1;
    flex-wrap: wrap;
    width: 100%;
}

.wrap_color_value {
    display: flex;
    margin-left: 15px;
    margin-bottom: 10px;
    align-items: center;
}

.color_circle {
    height: 20px;
    width: 20px;
    borderRadius: 50%;
}