

.wrap_header_logo {
    width: 60px;
    height: 60px;
}

.header_logo {
    width: 100%;
    height: 100%;
}