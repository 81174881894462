@font-face {
  font-family: gilroy; /* Имя шрифта */
  src: local('gilroy'), url(./fonts/grigoriy.otf) format('opentype'); /* Путь к файлу со шрифтом */
}

button,
textarea,
input,
select,
div,
a{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hypen {

  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;
  -moz-hyphens: auto;
  -moz-hyphenate-limit-chars: 6 3 3;
  -moz-hyphenate-limit-lines: 2;
  -moz-hyphenate-limit-last: always;
  -moz-hyphenate-limit-zone: 8%;
  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: 6 3 3;
  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-last: always;
  -ms-hyphenate-limit-zone: 8%;
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;

}

canvas {
  /*image-rendering: smooth;*/
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*presets*/



.MuiTableCell-root {
  /*width: 100% !important;*/
}

body {

  font-size: 2.4vh;

  font-family: gilroy;

  background-color: #E3E9F7;

  padding: 0;
  margin: 0;

}


body {
  margin: 0;
  font-family: gilroy;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 2.4vh;

  font-family: gilroy;

  background-color: #E3E9F7;
  /*display: grid;*/
  padding: 0;
  margin: 0;

  /*align-content: baseline;*/

  /*grid-template-columns: 1fr;*/
  /*grid-template-rows: 4% 96%;*/

  /*min-height: 640px;*/
  /*min-width: 640px;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
