.stat_mp_block {
    /*margin: 15px;*/
}

.wrap_switch {
    width: 62px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    background: linear-gradient(
            -244deg, #d0d8e9, #ffffff 80%);
}

.wrap_frame_info {
    display: none;
    /*justify-content: center;*/
}

.frame_info_column_with_logo_mob {
    display: none;
    justify-content: space-between;
    width: 100%;
}

.frame_info {
    width: 100%;
    display: none;
    justify-content: space-between;
    margin-top: 55px;
}

.frame_info_column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.frame_info_block {
    background: linear-gradient(
            0deg, #E3E9F7, #E3E9F7);
    /* global_wrappers-shadow: -9px -9px 24px rgb(255 255 255 / 57%), 20px 22px 40px rgb(0 0 0 / 8%); */
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 20px;
    color: #2F2A61;

    font-family: 'gilroy';
    font-weight: 600;
    width: 350px;
    /*height: 200px;*/
    font-size: 20px;
}

.frame_info_column_with_logo_mob {
    display: none;
    justify-content: space-between;
    width: 100%;
}

.circle_col_right {
    text-align: right;
}

.frame_info_column_with_logo {
    padding: 0px 50px;
}


.col_left {
    align-items: flex-start;
}


.circle_col_left {
    text-align: left;
}


.flex_ {
    display: flex;
}

.wh_sp_no_wr {
    white-space: nowrap;
}

.fw_norm {
    font-weight: normal;
}

.flex_wrap_yes {
    flex-wrap: wrap;
}

.close_info_frame_btn {
    width: 100%;
    box-shadow: -0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%);
    padding: 10px 20px 10px;
    color: #5E5AB9;
    font-family: gilroy;
    transition: all 0.4s;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 12px;
    margin-bottom: 25px;
}
.close_info_frame_btn:active {
    box-shadow: none;
    transform: translate(-0.4vw, 0.4vw);
}

.col_right {
    align-items: flex-end;
}


@media screen and (max-width: 1400px) {
        .frame_info_block {

            width: 330px;

        }
}

@media screen and (max-width: 1300px) {
        .frame_info_block {

            width: 295px;

        }
}

@media screen and (max-width: 1100px) {


    .frame_info_block {
        width: 230px;
        /*height: 245px;*/

    }
}

@media screen and (max-width: 950px) {
    .frame_info_block {
        width: 200px;
        /*height: 300px;*/

    }
}

@media screen and (max-width: 900px) {
        .close_info_frame_btn {
            margin-bottom: 20px;
        }
        .wrap_logo {
            width: 170px;
            height: 170px;
        }

        #logo_in_frame_svg {
            width: 180px;
            height: 180px;
        }
        .frame_info_block {
            width: 210px;
            /*height: 320px;*/
        }
}



@media screen and (max-width: 850px) {


        .frame_info_column_with_logo {
            display: none;
        }
        .frame_info_column_with_logo_mob {
            display: flex;
            /* margin-bottom: 40px; */
            margin-top: 40px;
        }
        .wrap_frame_info {

            flex-direction: column;
            align-items: center;
        }

        .frame_info_block {
            width: 300px;
            /*height: 200px;*/
        }

        .wrap_frame_info {

            flex-direction: column;
            align-items: center;
        }
}

@media screen and (max-width: 650px) {
        .frame_info_block {
            width: 200px;
            /*height: 250px;*/
            font-size: 18px;
        }
}


@media screen and (max-width: 550px) {
        #logo_in_frame_svg {
            width: 150px;
            height: 150px;
        }

        .wrap_logo {
            width: 145px;
            height: 145px;
        }
}

@media screen and (max-width: 500px) {


    #logo_in_frame_svg {
        width: 150px;
        height: 150px;
    }

}

@media screen and (max-width: 450px) {
        .circle_col_left {
            text-align: center;
        }

        .circle_col_right {
            text-align: center;
        }

        .frame_info {
            flex-direction: column;
        }
}

