

.wrap_title_block_header_position_setting {
    display: flex;
    justify-content: center;
    padding: 24px 12px;
    border-bottom: 2px solid #2f2a61;
}


.vertical_divider_blocks{
    height: 100%;
    width: 2px;

    background: #2f2a61;
}


.wrap_blocks_d_and_m_header_setting {
    margin-top: 24px;

    display: flex;
    justify-content: space-between;
}



.wrap_block_desktop_header_setting {
    flex: 1;
}

.wrap_block_mobile_header_setting {
    flex: 1;
}

@media (max-width: 350px) {
    .wrap_blocks_d_and_m_header_setting {
        flex-direction: column;
    }
    .wrap_block_mobile_header_setting {
        margin-top: 24px;
    }
}

