

.wrap_err_text {

    display: flex;
    flex-direction: column;
}

.err_text {
    text-align: left;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 5px;

    color: #f44336;
    font-size: 14px;
    font-family: gilroy;
    font-weight: 600;
    font-style: normal;

    /*background: linear-gradient(#F9A91A, #FF5340 );*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    /*color: #f44336;*/
}