

.accept_btn.hided {
    display: none;
}


.outer {
    display: inline-block;
    box-shadow: 0 0 30px #000;
}
.purple_button_outer {
    display: inline-block;
    box-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.2);
    border-radius: 12px;
    background: transparent;
}
.purple_button_outer:active {
    box-shadow: none;
}

.purple_button_outer.disabled {
    opacity: 0.2;
    pointer-events: none;
}


.purple_button_inner {
    box-shadow: none;
    cursor: pointer;
    border: none;
    outline: none;
    /*padding-top: 13px;*/
    /*padding-bottom: 13px;*/
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5E5AB9;
    border-radius: 12px;
    transition: .4s ;
    text-align: center;
    /*transform-style: preserve-3d;*/
}

.purple_button_inner:active {
    transform: translate(-0.4vw, 0.4vw);
    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.1), inset 6px 8px 19px rgba(0, 0, 0, 0.08);
}



.light_button {
    cursor: pointer;
    border: none;
    outline: none;
    padding-top: 13px;
    padding-bottom: 13px;

    background: #E3E9F7;
    box-shadow: -5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12);
    border-radius: 12px;
    transition: transform 0.4s, box-shadow 0.4s;
}
.light_button_text {
    font-family: 'gilroy', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #2F2A61;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.light_button:active {
    font-family: Gilroy semibold;
    box-shadow: none;
    transform: translate(-0.4vw, 0.4vw);
    transition: transform 0.4s, box-shadow 0.4s;
}

.wrap_select_light_btn {
    width: 100%;
}

.wrap_select_light_btn.cols_ {
    background: #E3E9F7;
    box-shadow: -5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12);
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 30px;
}

.light_button.select_btn {
    position: relative;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    padding: 0;

    width: 100%;
    margin-top: 30px;
    transition: transform 0.4s, box-shadow 0.4s;
}

.wrapper_light_select_btn_text {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 5px;
    padding-right: 5px;
   flex: 1;
}

.wrapper_light_select_btn_icon {
    /*padding-top: 13px;*/
    /*padding-bottom: 13px;*/
    /*height: 100%;*/
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    align-items: center;
    flex: 0;
    border-left: 1px solid rgba(94,90,185,0.16);
    transition: opacity 0.4s;
}

.wrapper_light_select_btn_icon:hover > svg.icon_arrow_select_btn {
    fill: rgba(94,90,185,1);
}
.icon_arrow_select_btn {
    fill: rgba(94,90,185,0.3);
    transition: fill 0.4s;

}

.icon_arrow_select_btn:hover {
    fill: rgba(94,90,185,1);
}

.light_button.select_btn:active {
    font-family: 'gilroy', serif;
    box-shadow: -5px -5px 7px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(23, 23, 23, 0.12);
    transform: none;
    transition: none;
}

.light_button.select_btn.active:active {
    font-family: Gilroy semibold;
    box-shadow: none;
    transform: translate(-0.4vw, 0.4vw);
    transition: transform 0.4s, box-shadow 0.4s;
}





.purple_button {
    cursor: pointer;
    border: none;
    outline: none;
    padding-top: 13px;
    padding-bottom: 13px;
    background: #5E5AB9;
    box-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.2);
    border-radius: 12px;
    transition: box-shadow 0.4s ;
}

.purple_button.disabled {
    opacity: 0.2;
}

.purple_button:active {
    background:#5E5AB9;
    box-shadow: none;
    /*box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.1), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
            /*inset -4px -5px 17px rgba(255, 255, 255, 0.1), inset 6px 8px 19px rgba(0, 0, 0, 0.08);*/
    transition: 0.4s ;
}

.purple_button_text {
    font-family: 'gilroy', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: rgba(255, 255, 255, 1);
}

.button_open_feedback_modal {
    color: #57559a;
    margin-right: 10px;
    font-family: 'gilroy', serif;
    font-size: 16px;
}

.text_btn {
    /*marginTop: 30px;*/
    color: rgba(47, 42, 97, 0.4);
    font-family: 'gilroy', serif;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    cursor: pointer;
    font-weight: 500;
    transition:    font-weight 0.4s,  color 0.4s;


}

.text_btn:hover {
    color: #5e5ab9;
    transition: color 0.4s;
    font-weight: 600;

}

.wrap_select_all.text_btn.hover {
    cursor: default;
}

.text_btn.hover {
    color: #5e5ab9;
    transition: color 0.4s;
    font-weight: 600;
}

.text_btn.red_color {
    color: rgba(244, 67, 54, 0.4);
    transition: color 0.4s;
    font-weight: 600;
    transition: color 0.4s;
}

.text_btn.red_color:hover {
    color: rgba(244, 67, 54, 1);
}


.typ_text_btn {
    color: #999bba;
    margin-top: 15px;

    cursor: pointer;
    text-align: center;
    transition: all 0.4s;
}

.text_btn_login {
    color:  #2F2A61;
    marginTop: 15px;
    height: 50px;
    cursor: pointer;
    textAlign: center;
    marginLeft: 13;
    fontSize: 13;
    transition: color 0.4s;
}

.log_btn {
    background-color: #5e5ab9;
    color: #fff;
    margin-top: 30px;
    height: 40px;
    border-radius: 12px;
    text-transform: none;
    font-weight: bold;
    font-size: 14px;
    font-family: gilroy;
    width: 100%;
    box-shadow: -9px -9px 16px rgba(255, 255, 255, 0.6), 4px 4px 8px rgba(63, 60, 255, 0.2);
}

.log_btn:hover {
    background: #5e5ab9;
    boxShadow: none;
}

.text_btn_login:hover {
    color:  #999bba;
    transition: color 0.4s;
}

.typ_text_btn:hover {
    color: #5e5ab9;
}