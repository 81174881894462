
.router_page_container_wrap {
    padding: 15px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.route_btn_key_text {
    font-weight: normal;
}


.router_page_container {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/

    /*margin: 15px;*/
    align-items: center;
    flex-direction: column;
    display: block;

    min-height: 200px;
    position: relative;
    padding: 15px 15px 15px;

    background: linear-gradient(0deg, #E3E9F7, #E3E9F7);
    box-shadow: -0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%);
    border-radius: 24px;
    width: 100%;
    cursor: default;
    box-sizing: border-box;
}


.select_page_title {
    margin: 0;
    /*margin-bottom: 20px;*/
    font-weight: bold;
    font-size: 24px;
    color: #2F2A61;
    font-family: "gilroy";
    text-align: center;
}

.wrap_routes {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*margin-top: 20px;*/
    width: 100%;
}

.route_btn {
    margin-top: 30px;
    width: 100%;
}

.to_home_btn_select_page {
    margin-top: 30px;
}

.to_home_btn_select_page {
    margin-top: 30px;
}

.center_container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



@media (max-width: 600px) {
    .center_container {
        height: 100%;
    }

}