
.content_wrap.sheme_content_wrap {
    margin-top: 25px;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 40px;
    margin-bottom: 50px;
    position: relative;

    overflow: hidden;
}

@media (max-width: 600px) {
    .content_wrap.sheme_content_wrap {
        margin-top: 0px;

        padding-bottom: 0px;
        margin-bottom: 0px;

    }
}